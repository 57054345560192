import proceedingsLess from "./css/order.less";
import Dgform from "../../components/dg-form";
import { Table, Button, Form, Pagination } from "antd";
import type { FormInstance } from "antd";
import { memo, useEffect, useRef, useState } from "react";
import { itmeType } from "../../types";
import ajax from "../../assets/js/ajax";
import type { ColumnsType } from "antd/es/table";
import { NavigateFunction, useNavigate } from "react-router-dom";
// import EmptyPage from "../../components/emptyPage";
// import { hasPermission } from "../../assets/js/hasPermission";

let navigate: NavigateFunction | null = null;

interface DataType {
  insureEffectTimeEnd: string;
  insureEffectTimeStart: string;
  key: React.Key;
  title: string;
  dataIndex: string;
  align: string;
}

interface baoHan {
  pageNo: number | null; // 页数
  pageSize: number | null; // 条数
  sortDirection?: string; // 排序方向
  sortField?: string; // 排序字段
  status?: number | null; // 状态
  insureOrderNo?: string; // 安责险保函编号
  projectName?: string; // 项目名称
  tenderName?: string; // 服务公司名称
}

export default memo(() => {
  navigate = useNavigate();

  const columns: ColumnsType<DataType> = [
    {
      title: "项目名称",
      dataIndex: "projectName",
      key: "projectName",
      align: "center",
    },
    {
      title: "施工企业单位",
      key: "tenderName",
      dataIndex: "tenderName",
      align: "center",
    },
    {
      title: "安责险保函编号",
      dataIndex: "insureOrderNo",
      key: "insureOrderNo",
      align: "center",
    },
    {
      title: "保函期限",
      dataIndex: "term",
      key: "term",
      align: "center",
      render: (_, record) => {
        return (
          <div>
            {record.insureEffectTimeStart}-{record.insureEffectTimeEnd}
          </div>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "",
      key: "oper",
      align: "center",
      width: 80,
      render: (text, record: any) => {
        return (
          <div>
            <span
              className="aui-link"
              onClick={() => {
                navigate!("/admin/accidentConfigure", {
                  state: {
                    type: "create",
                    safeLiabilityTradeNo: record.tradeNo,
                    insureEffectTimeStart: record.insureEffectTimeStart,
                    insureEffectTimeEnd: record.insureEffectTimeEnd,
                  },
                });
              }}
            >
              选择
            </span>
          </div>
        );
      },
    },
    //
  ];

  const [totalItems, setTotalItems] = useState<number>(0);
  // const [statusAll, setStatusAll] = useState<any[]>([]); //状态选择框
  // const [baoquanTypes, setquanTypes] = useState<any[]>([]); //保函类型选择框
  const [formItems, setFormItems] = useState<itmeType[]>([
    {
      label: "项目名称",
      type: "input",
      key: "projectName",
      des: "请输入项目名称",
    },

    {
      label: "施工企业名称",
      type: "input",
      key: "tenderName",
      des: "请输入施工企业名称",
    },
    {
      label: "安责险保函编号",
      type: "input",
      key: "insureOrderNo",
      des: "请输入安责险保函编号",
    },
  ]);

  const [data, setData] = useState<any>([]);

  const [formData, setFormData] = useState<baoHan>({
    pageNo: 1, // 页数
    pageSize: 10, // 条数
    sortDirection: "", // 排序方向
    sortField: "", // 排序字段
    status: 9, // 状态
    insureOrderNo: "", // 订单编号
    projectName: "",
    tenderName: "",
  });

  const formRef = useRef<{
    getFormRef: () => FormInstance<any>;
    getValues: () => any;
  }>();

  const handelSeach = (values: any) => {
    setFormData({
      pageNo: 1,
      pageSize: 10,
      ...values,
    });
  };

  const handelPageChange = (pageNo: number, pageSize: number) => {
    setFormData({
      pageNo,
      pageSize,
      ...formRef.current?.getValues(),
    });
  };

  const getList = (data?: any) => {
    ajax.post({
      url: "/openPlatform/engineeringSafeLiability/list",
      data: Object.assign(
        formData,
        data || { createAccident: false, status: 9 }
      ),
      self: this,
      success: (res) => {
        setData(() => {
          return res.result.map((e: any, i: number) => {
            return { ...e, key: i };
          });
        });
        setTotalItems(res.totalItems);
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    // if (hasPermission("openPlatform:purchaseContract:list")) {
    getList();
    // }
  }, [formData]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {/* {hasPermission("openPlatform:purchaseContract:list") ? ( */}
      <div>
        <div className="pb-[12]">
          <span className="t-[16] aui-192530 pr-[4]">
            选择安责险订单/项目信息
          </span>
        </div>
        <div className={`aui-bg-white ${proceedingsLess.wrap} `}>
          <div className={` ${proceedingsLess.bor_bttom}  p-[26]`}>
            <Dgform ref={formRef} items={formItems} handelSeach={handelSeach}>
              <Form.Item className="ml-[auto] mr-[0]">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="mr-[10]"
                >
                  搜索
                </Button>
              </Form.Item>
            </Dgform>
          </div>
          <div className="p-[26]">
            <Table columns={columns} pagination={false} dataSource={data} />
            <div className="text-center pt-[24]">
              <Pagination
                onChange={handelPageChange}
                total={totalItems}
                showTotal={(total) => `总条数 ${total}`}
              ></Pagination>
            </div>
          </div>
        </div>
      </div>
      {/* ) : (
        <EmptyPage />
      )} */}
    </div>
  );
});
