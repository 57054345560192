import Dgupload from "../../components/dg-upload";
import indexLess from "./css/companyEdit.less";
import { message } from "antd";
import { Button, Form, Input, Select } from "antd";

import { useState, useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import ajax from "../../assets/js/ajax";
const { Option } = Select;
const { TextArea } = Input;
export default memo(() => {
	const navigate = useNavigate();
	let [status, setStatus] = useState<any>([]);
	let [refForm] = Form.useForm();

	let handlerSubmint = (values: any) => {
		ajax.post({
			url: "/openPlatform/financial/auth",
			data: values,
			self: this,
			success: (res) => {
				message.success("提交成功");
				navigate(-1);
			},
			fail: () => {},
		});
	};
	/**
	 * @author 伟健 / 张
	 * @name 账号信息
	 * @param
	 * @return
	 */
	let getAccountInfo = () => {
		ajax.post({
			url: "/openPlatform/user/current?t=companyEdit",
			data: {},
			self: this,
			success: (res) => {
				// setData(res.financialInstitution || {});
				let enterprise = res.financialInstitution;

				refForm.setFieldsValue({ address: enterprise.enterpriseAddress, ...enterprise });
			},
			fail: () => {},
		});
	};
	/**
	 * @author 伟健 / 张
	 * @name 企业类型
	 * @param
	 * @return
	 */
	let getFinancialTypes = () => {
		ajax.post({
			url: "/openPlatform/common/allFinancialTypes",
			data: {},
			self: this,
			success: (res) => {
				console.log(res);
				if (res) {
					setStatus(() => [...res]);
				}
			},
			fail: () => {},
		});
	};

	useEffect(() => {
		getAccountInfo();
		getFinancialTypes();
	}, []);
	return (
		<div className="">
			<div className="pb-[12]">
				<span className="t-[16] aui-192530 pr-[4]">企业认证</span>
			</div>
			<div className={`aui-bg-white ${indexLess.wrap}`}>
				{/* form={form} */}
				<Form labelCol={{ span: 4 }} wrapperCol={{ span: 8 }} form={refForm} onFinish={handlerSubmint}>
					<div className={`pl-[26] pr-[26] pb-[20] pt-[20] ${indexLess.bor_bttom}`}>
						<div className="pb-[10]">
							<span className={`t-[18] aui-192530 ${indexLess.title}`}>基本信息</span>
						</div>

						<Form.Item label="企业营业执照" wrapperCol={{ span: 11 }} name="businessLicense" rules={[{ required: true, message: "" }]}>
							<div className="t-[12] aui-788593 pb-[10] pt-[8]">请上传最新的营业执照，支持.jpg .jpeg .png 格式照片，大小不超过10M</div>
							<Form.Item name="businessLicense" rules={[{ required: true, message: "请上传企业营业执照" }]}>
								<Dgupload></Dgupload>
							</Form.Item>
						</Form.Item>

						<Form.Item label="企业名称" name="enterpriseName" rules={[{ required: true, message: "请输入企业名称" }]}>
							<Input placeholder="请输入企业名称" />
						</Form.Item>
						<Form.Item label="企业类型" name="type" rules={[{ required: true, message: "请输入企业类型" }]}>
							<Select style={{ width: 130 }} placeholder="请选择企业类型">
								{status.map((el: any, index: number) => {
									return (
										<Option key={index} value={el.key}>
											{el.value}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
						<Form.Item label="统一信用代码" name="socialCreditCode" rules={[{ required: true, message: "请输入统一信用代码" }]}>
							<Input placeholder="请输入统一信用代码" />
						</Form.Item>
						<Form.Item label="企业地址" name="address" rules={[{ required: true, message: "请输入企业地址" }]}>
							<TextArea placeholder="请输入企业地址" rows={4} />
						</Form.Item>
						<Form.Item label="其他材料" wrapperCol={{ span: 11 }} name="otherAttachments" rules={[{ required: false, message: "" }]}>
							<div>
								<div className="t-[12] aui-788593 pb-[10] pt-[8]">支持拓展名.rar .zip .doc .docx .pdf .jpg</div>
								<Dgupload type={['*']} button={true}></Dgupload>
							</div>
							{/* <Form.Item name="otherAttachments" rules={[{ required: false, message: "" }]}>
								<Dgupload button={true}></Dgupload>
							</Form.Item> */}
						</Form.Item>
					</div>
					<div className=" pl-[26] pr-[26] pb-[20] pt-[20]">
						<div className="pb-[16]">
							<span className={`t-[18] aui-192530 ${indexLess.title}`}>法人信息</span>
						</div>
						<Form.Item label="法人姓名" name="legalPersonName" rules={[{ required: true, message: "请输入法人姓名" }]}>
							<Input placeholder="请输入法人姓名" />
						</Form.Item>
						<Form.Item
							label="法人手机号"
							name="legalPersonMobile"
							rules={[
								{ required: true, message: "请输入法人手机号" },
								{ pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/, message: "请输入法人手机号" },
							]}
						>
							<Input maxLength={11} placeholder="请输入法人手机号" />
						</Form.Item>

						<Form.Item wrapperCol={{ offset: 4 }}>
							<Button type="primary" htmlType="submit" className="w-150">
								提交
							</Button>
						</Form.Item>
					</div>
				</Form>
			</div>
		</div>
	);
});
