import indexLess from "../css/index.less";
import SearchPng from "../../../assets/img/search.png";
import ajax from "../../../assets/js/ajax";
import { useState } from "react";
import { Empty, Input, message } from "antd";
import { useNavigate } from "react-router-dom";

interface PropT {
  visible: boolean;
  close: () => void;
  clickItem: (id: number | string, classifyId: number | string) => void;
}

export default (props: PropT) => {
  const { visible, close, clickItem } = props;

  const navigate = useNavigate();
  const [title, setTitle] = useState<string>("");
  const [searchList, setSearchList] = useState<any[]>([]);

  const goDetail = (id: number | string, classifyId: number | string) => {
    clickItem(id, classifyId);
  };

  const onSearch = () => {
    if (!title || title.length == 0) {
      message.warning("请输入问题");
      return;
    }
    ajax.post({
      url: "/openPlatform/support/questionSearch",
      data: { title },
      self: this,
      success: (res) => {
        setSearchList([...res]);
      },
      fail: () => {},
    });
  };

  return visible ? (
    <div
      id="mask"
      className={indexLess.mask}
      onClick={(e) => {
        const eleId = (e.target as HTMLDivElement).id;
        if (eleId && eleId == "mask") {
          close();
        }
      }}
    >
      <div className={indexLess.maskContainer}>
        <div className={indexLess.searchBox}>
          <Input
            placeholder="请输入问题"
            value={title}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setTitle(e.target.value);
            }}
          />
          <div className={indexLess.searchBtn} onClick={() => onSearch()}>
            <img src={SearchPng} alt="" />
          </div>
        </div>

        <div className={indexLess.searchContainer}>
          {searchList.length > 0 ? (
            searchList.map((item) => {
              return (
                <div
                  className={indexLess.searchItem}
                  onClick={() => goDetail(item.id, item.classifyId)}
                >
                  {`${item.classifyName}-${item.title}`}
                </div>
              );
            })
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};
