import Header from "./components/header";
import Footer from "./components/footer";

import protocolLess from "./protocol.less";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ajax from "./assets/js/ajax";
import DgCopyright from "./components/dg-copyright";
export default () => {
	let [info, setInfo] = useState({
		realName: "",
	});
	let [html, setHtml] = useState("");

	const navigate = useNavigate();

	/**
	 * @author 伟健 / 张
	 * @name 当前用户信息
	 * @param
	 * @return
	 */
	let getgetUserProtocol = () => {
		ajax.post({
			url: "/openPlatform/auth/getUserProtocol",
			data: {},
			self: this,
			success: (res) => {
				if (res) {
					setHtml(res.content);
				}
			},
			fail: () => {},
		});
	};
	useEffect(() => {
		getgetUserProtocol();
	}, []);
	return (
		<div>
			<Header></Header>

			<div className="w-[1200] m-[auto]">
				<div
					className={protocolLess.wrap}
					dangerouslySetInnerHTML={{
						__html: html,
					}}
				></div>
			</div>
			{/* <Footer></Footer> */}
			<DgCopyright paddingTop="0px" paddingBottom="30px" />
		</div>
	);
};
