import proceedingDetailLess from "../css/proceedingDetail.less";

interface FilsType {
  files: any[];
  time: string;
}

const FileList = (props: FilsType) => {
  const { files, time } = props;
  return (
    <div className={proceedingDetailLess.filesValue}>
      <div className={proceedingDetailLess.filesName}>
        {files.map((curr: any) => {
          if (curr.enable) {
            return (
              <a
                href={curr.path}
                target="_blank"
                className={proceedingDetailLess.filesActive}
              >
                {curr.name}
              </a>
            );
          } else {
            return (
              <span className={proceedingDetailLess.filesEnable}>
                {curr.name}
              </span>
            );
          }
        })}
      </div>
      <div className={proceedingDetailLess.filesActiveTime}>{time}</div>
    </div>
  );
};
export default FileList;
