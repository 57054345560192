import { formatDate } from "../assets/js/util";
import { Form, Input, Select, DatePicker, Row, Col } from "antd";
import React, { useEffect } from "react";
import { forwardRef, useImperativeHandle } from "react";
import { itmeType } from "../types";
const { RangePicker } = DatePicker;
const { Option } = Select;
interface propsType {
  items: itmeType[];
  children: React.ReactElement | React.ReactElement[];
  handelSeach: (valeus: any) => void;
  init?: (valeus: any) => void;
}

//  { key: "name", label: "名称", des: "请输入名称", type: "input" },

let renderItem = (items: itmeType[]) => {
  return items.map((el, index: number) => {
    if (el.type === "input") {
      return (
        <Col key={index} span={8}>
          <Form.Item name={el.key} key={index} style={{ marginBottom: "18px" }}>
            <Input allowClear placeholder={el.des} />
          </Form.Item>
        </Col>
      );
    }
    if (el.type === "date") {
      return (
        <Col key={index} span={8}>
          <Form.Item name={el.key} key={index} style={{ marginBottom: "18px" }}>
            <RangePicker allowClear />
          </Form.Item>
        </Col>
      );
    }
    if (el.type === "select") {
      return (
        <Col key={index} span={8}>
          <Form.Item name={el.key} key={index} style={{ marginBottom: "18px" }}>
            <Select allowClear placeholder={el.des}>
              <Option value="">全部</Option>
              {el.options?.map((el, index) => {
                return (
                  <Option key={index} value={el.key}>
                    {el.value}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      );
    }
  });
};

export default forwardRef((props: propsType, ref: any) => {
  let { items, handelSeach, children } = props;
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => {
    return {
      getFormRef: () => {
        return form;
      },
      getValues: correctionData,
    };
  });
  /**
   * @author 伟健 / 张
   * @name 校正数据
   * @param
   * @return
   */
  let correctionData: any = (values?: any) => {
    if (!values && form) {
      values = form.getFieldsValue();
    }
    Object.keys(values).forEach((key) => {
      if (key.indexOf(",") > -1) {
        let begin = key.split(",")[0];
        let end = key.split(",")[1];
        let val = values[key];
        if (val) {
          values[begin] = formatDate(val[0])
            ? formatDate(val[0]).slice(0, 11) + "00:00:00"
            : "";
          values[end] = formatDate(val[1])
            ? formatDate(val[1]).slice(0, 11) + "23:59:59"
            : "";
        } else {
          values[begin] = "";
          values[end] = "";
        }
        delete values[key];
      } else {
        if (!values[key] && values[key] !== 0) {
          values[key] = "";
        }
      }
    });

    return values;
  };

  useEffect(() => {}, []);

  return (
    <Form
      form={form}
      layout="horizontal"
      onFinish={(values: any) => {
        handelSeach(correctionData(values));
      }}
    >
      <Row gutter={[20, 0]}>
        <Col span={18}>
          <Row gutter={[20, 0]}>{renderItem(items)}</Row>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          {children}
        </Col>
      </Row>
    </Form>
  );
});
