import proceedingsLess from "./css/order.less";
import Dgform from "../../components/dg-form";
import { Table, Button, Form, Pagination, message, Popconfirm } from "antd";
import type { FormInstance } from "antd";
import { memo, useEffect, useRef, useState } from "react";
import { itmeType } from "../../types";
import ajax from "../../assets/js/ajax";
import type { ColumnsType } from "antd/es/table";
import { NavigateFunction, useNavigate } from "react-router-dom";
import EmptyPage from "../../components/emptyPage";
import { hasPermission } from "../../assets/js/hasPermission";
// import EmptyPage from "../../components/emptyPage";
// import { hasPermission } from "../../assets/js/hasPermission";

let navigate: NavigateFunction | null = null;

interface DataType {
  key: React.Key;
  title: string;
  dataIndex: string;
  align: string;
}

interface baoHan {
  pageNo: number | null; // 页数
  pageSize: number | null; // 条数
  sortDirection?: string; // 排序方向
  sortField?: string; // 排序字段
  status?: number | null; // 状态
  respondentBeau?: number | null; // 被投诉人类型
  respondentName?: string; // 被投诉人
  tradeNo?: string; // 订单编号
  projectName?: string; // 项目名称
  serviceName?: string; // 服务名称
  safeLiabilityTradeNo?: string; // 服务订单编号
}

export default memo(() => {
  navigate = useNavigate();

  // 确定删除
  const confirm = (id: any) => {
    ajax.post({
      url: "/openPlatform/serviceComplaint/delete",
      data: { id },
      self: this,
      success: (res) => {
        if (res.code == 200) {
          getList();
          message.success("删除成功");
        }
      },
      fail: () => {},
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "投诉编号",
      dataIndex: "tradeNo",
      key: "tradeNo",
      align: "center",
    },
    {
      title: "提交时间",
      key: "submitDate",
      dataIndex: "submitDate",
      align: "center",
    },
    {
      title: "被投诉人类型",
      dataIndex: "respondentBeauName",
      key: "respondentBeauName",
      align: "center",
    },
    {
      title: "被投诉人",
      dataIndex: "respondentName",
      key: "respondentName",
      align: "center",
    },
    {
      title: "服务名称",
      dataIndex: "serviceName",
      key: "serviceName",
      align: "center",
    },
    {
      title: "项目名称",
      dataIndex: "projectName",
      key: "projectName",
      align: "center",
    },
    {
      title: "投诉内容",
      dataIndex: "complainantRemark",
      key: "complainantRemark",
      align: "center",
    },
    {
      title: "处理状态",
      dataIndex: "statusName",
      key: "statusName",
      align: "center",
      width: 80,
      fixed: "right",
    },
    {
      title: "操作",
      dataIndex: "",
      key: "oper",
      align: "center",
      width: 120,
      fixed: "right",
      render: (text, record: any) => {
        return (
          <div className="flex justify-center">
            <span
              className="aui-link pl-[4]"
              onClick={() => {
                navigate!("/admin/accident/complaintDetail", {
                  state: { id: record.id },
                });
              }}
            >
              查看
            </span>
            {record.status == 0 && (
              <div>
                <span
                  className="aui-link pl-[4]"
                  onClick={() => {
                    navigate!("/admin/accident/complaint", {
                      state: { tradeNo: record.serviceTradeNo, id: record.id },
                    });
                  }}
                >
                  编辑
                </span>
                <span className="aui-link pl-[4]">
                  <Popconfirm
                    title="删除后内容不可恢复，是否继续?"
                    onConfirm={() => {
                      confirm(record.id);
                    }}
                    okText="确定"
                    cancelText="取消"
                  >
                    <a href="#">删除</a>
                  </Popconfirm>
                </span>
              </div>
            )}
          </div>
        );
      },
    },
    //
  ];

  const [totalItems, setTotalItems] = useState<number>(0);
  const [statusAll, setStatusAll] = useState<any[]>([]); //状态选择框
  const [statusAll2, setStatusAll2] = useState<any[]>([]); //状态选择框
  // const [baoquanTypes, setquanTypes] = useState<any[]>([]); //保函类型选择框
  const [formItems, setFormItems] = useState<itmeType[]>([
    {
      label: "投诉编号",
      type: "input",
      key: "tradeNo",
      des: "请输入投诉编号",
    },
    {
      label: "被投诉人类型",
      type: "select",
      options: statusAll2,
      key: "respondentBeau",
      des: "请选择被投诉人类型",
    },
    {
      label: "被投诉人名称",
      type: "input",
      key: "respondentName",
      des: "请输入被投诉人名称",
    },
    {
      label: "服务名称",
      type: "input",
      key: "serviceName",
      des: "请输入服务名称",
    },
    {
      label: "项目名称",
      type: "input",
      key: "projectName",
      des: "请输入项目名称",
    },
    {
      label: "安责险保函编号",
      type: "input",
      key: "safeLiabilityTradeNo",
      des: "请输入安责险保函编号",
    },
    {
      label: "处理状态",
      type: "select",
      options: statusAll,
      key: "status",
      des: "请选择处理状态",
    },
  ]);

  const [data, setData] = useState<any>([]);

  const [formData, setFormData] = useState<baoHan>({
    pageNo: 1, // 页数
    pageSize: 10, // 条数
    sortDirection: "", // 排序方向
    sortField: "", // 排序字段
    status: null, // 状态
    respondentBeau: null, // 状态
    respondentName: "", // 状态
    tradeNo: "", // 订单编号
    projectName: "",
    serviceName: "",
    safeLiabilityTradeNo: "",
  });

  const formRef = useRef<{
    getFormRef: () => FormInstance<any>;
    getValues: () => any;
  }>();

  const handelSeach = (values: any) => {
    setFormData({
      pageNo: 1,
      pageSize: 10,
      ...values,
    });
  };

  const handelPageChange = (pageNo: number, pageSize: number) => {
    setFormData({
      pageNo,
      pageSize,
      ...formRef.current?.getValues(),
    });
  };

  // 获取投诉状态
  const getStatus = () => {
    ajax.post({
      url: "/openPlatform/common/allSafeLiabilityServiceComplaintStatus",
      data: {},
      self: this,
      success: (res) => {
        res.forEach((el: any) => {
          statusAll.push({ ...el });
        });
        // setStatusAll([...res]);
      },
      fail: () => {},
    });
  };

  // 获取被投诉人类型
  const getStatus2 = () => {
    ajax.post({
      url: "/openPlatform/common/allComplainantOrRespondentList",
      data: {},
      self: this,
      success: (res) => {
        res.forEach((el: any) => {
          statusAll2.push({ ...el });
        });
        // setStatusAll([...res]);
      },
      fail: () => {},
    });
  };

  const getList = (data?: any) => {
    ajax.post({
      url: "/openPlatform/serviceComplaint/page",
      data: Object.assign(formData, data || {}),
      self: this,
      success: (res) => {
        setData(() => {
          return res.result.map((e: any, i: number) => {
            return { ...e, key: i };
          });
        });
        setTotalItems(res.totalItems);
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    getStatus();
    getStatus2();
  }, []);

  useEffect(() => {
    if (hasPermission("openPlatform:serviceComplaint:page")) {
    getList();
    }
  }, [formData]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {hasPermission("openPlatform:serviceComplaint:page") ? (
        <div>
          <div className="pb-[12]">
            <span className="t-[16] aui-192530 pr-[4]">
              投诉列表
            </span>
          </div>
          <div className={`aui-bg-white ${proceedingsLess.wrap} `}>
            <div className={` ${proceedingsLess.bor_bttom}  p-[26]`}>
              <Dgform ref={formRef} items={formItems} handelSeach={handelSeach}>
                <Form.Item className="ml-[auto] mr-[0]">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="mr-[10]"
                  >
                    搜索
                  </Button>
                </Form.Item>
              </Dgform>
            </div>
            <div className="p-[26]">
              <Table
                columns={columns}
                pagination={false}
                dataSource={data}
                scroll={{ x: 1100 }}
              />
              <div className="text-center pt-[24]">
                <Pagination
                  onChange={handelPageChange}
                  total={totalItems}
                  showTotal={(total) => `总条数 ${total}`}
                ></Pagination>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyPage />
      )}
    </div>
  );
});
