import Dgupload from "../../components/dg-upload";
import { Button, Form, Input, InputNumber, Modal, Radio, message } from "antd";
import proceedingDetailLess from "./css/proceedingDetail.less";
import { memo, useEffect, useState } from "react";
import Dglist from "../../components/dg-list";
import Dglistitem from "../../components/dg-list-item";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import ajax from "../../assets/js/ajax";
import FileList from "./components/fileList";

let navigate: NavigateFunction | null = null;

export default memo(() => {
  navigate = useNavigate();
  const params = useLocation().state as {
    tradeNo: string;
  };

  const [data, setData] = useState({
    tradeNo: "",
    orderStatusName: "",
    orderAmount: "",
    gmtCreate: "", //提交时间
    payCallbackTime: "", //支付时间
    letterTime: "", //出函时间
    payVoucher: "", //支付凭证
    enterpriseName: "", //投保企业
    enterpriseCode: "", // 统一社会信用代码
    concatName: "", //联系人
    concatMobile: "", //联系人手机号
    enterpriseAddress: "", //企业地址
    projectName: "", //项目名称
    projectNo: "", //项目编号
    insureEffectTimeStart: "", //保函期限开始时间
    insureEffectTimeEnd: "", //保函期限截止时间
    marginAmount: "", //保证金金额(担保金额)
    guaranteeFormatTypeName: "", //保函格式要求类型名称
    guaranteeNum: "", //保函编号
    guaranteeTypeName: "", //保函品种
    financingInstitution: "", //担保机构
    auditRemark: "", //审核备注
    legalPersonIdCardFront: "", //法人身份证正面
    legalPersonIdCardReverse: "", //法人身份证反面
    issueName: "", //发包人
    issueUnifyCreditCode: "", //发包人统一信用代码
    orderStatusCode: -1,
    payType: "",
    counterGuarantee: null, //是否反担保
    projectAddress: "", //项目地址
    projectNatureName: "", //项目性质
    contractAmount: "", //合同金额
    guaranteeDays: "", //保函有效天数
  });

  const [qualiRankData, setQualiRankData] = useState({
    qualName: "", //企业资质名称
    rankName: "", //级别名称
  });

  const [refForm] = Form.useForm();
  const [unable, setUnable] = useState<boolean>(false);

  const [sentForm] = Form.useForm();
  const [sentUpdateForm] = Form.useForm();
  const [sendVisible, setSendVisible] = useState<boolean>(false);

  const [update, setUpdate] = useState<boolean>(false);
  const [offerForm] = Form.useForm();
  const [offerVisible, setOfferVisible] = useState<boolean>(false);
  const [stuffForm] = Form.useForm();
  const [stuffVisible, setStuffVisible] = useState<boolean>(false);

  // const [applicants, setApplicants] = useState<Applicant[]>([]);
  // const [applicant, setApplicant] = useState<Applicant>({});

  const [elePoliceFile, setElePoliceFile] = useState<string>("");
  const [insureFile, setInsureFile] = useState<string>("");
  // const [certificate1, setCertificate1] = useState<any[]>([]);
  // const [certificate2, setCertificate2] = useState<any[]>([]);

  const [guaranteeFiles, setGuaranteeFiles] = useState<any[]>([]);
  const [voucherFiles, setVoucherFiles] = useState<any[]>([]);
  const [relateFiles, setRelateFiles] = useState<any[]>([]);
  const [businessLicense, setBusinessLicense] = useState<any[]>([]); //企业营业执照
  const [buildContract, setBuildContract] = useState<any[]>([]); //施工合同
  const [issureFile, setIssureFile] = useState<any[]>([]); //招标文件
  const [recentlyAuditReport, setRecentlyAuditReport] = useState<any[]>([]); //近2年审计报告
  const [recentlyFinancialReport, setRecentlyFinancialReport] = useState<any[]>(
    []
  ); //近期财报
  const [enterpriseQualification, setEnterpriseQualification] = useState<any[]>(
    []
  ); //企业资质文件
  const [winbidNotice, setWinbidNotice] = useState<any[]>([]); //中标通知书
  const [baohanFormatFile, setBaohanFormatFile] = useState<any[]>([]); //保函格式文件
  const [other, setOther] = useState<any[]>([]); //其他
  const [replenishFile, setReplenishFile] = useState<any[]>([]); //补充材料
  const [signAgreement, setSignAgreement] = useState<any[]>([]); //电子签章文件
  const [cancelSignAgreement, setCancelSignAgreement] = useState<any[]>([]); //退保申请单文件
  const [constructionPermit, setConstructionPermit] = useState<any[]>([]); //施工许可证
  const [diffFiles, setDiffFiles] = useState<any[]>([]); //差异化存储佐证材料

  // const [qisuFiles, setQisuFiles] = useState<any[]>([]);
  // const [baoFiles, setBaoFiles] = useState<any[]>([]);
  // const [anjFiles, setAnjFiles] = useState<any[]>([]);
  // const [anjcFiles, setAnjcFiles] = useState<any[]>([]);
  // const [faFiles, setFaFiles] = useState<any[]>([]);
  // const [otherFiles, setOtherFiles] = useState<any[]>([]);
  // const [signFiles, setSignFiles] = useState<any[]>([]);

  // const [respondents, setRespondents] = useState<Respondent[]>([]);
  // const [respondent, setRespondent] = useState<Respondent>({});

  // const handleSwtichApplicant = (index: string) => {
  //   const cur = applicants[Number(index) - 1];
  //   setApplicant(cur);
  // };

  // const handleSwtichRespondent = (index: string) => {
  //   const cur = respondents[Number(index) - 1];
  //   setRespondent(cur);
  // };

  /**
   * @author: huangtaizhu@nongdaxia.com.cn
   * @description: 提交材料不足
   * @param {any} values
   * @return {*}
   */
  const handlerSubmitStuff = (values: any) => {
    ajax.post({
      url: "/openPlatform/engineeringContract/materialSupple",
      data: { tradeNo: params.tradeNo, ...values },
      self: this,
      success: (res) => {
        if (res) {
          setStuffVisible(false);
          getDetail();
          message.success("操作成功");
        }
      },
      fail: () => {},
    });
  };

  /**
   * @author: huangtaizhu@nongdaxia.com.cn
   * @description: 提交承保报价
   * @return {*}
   */
  const handlerSubmitOffer = (values: any) => {
    ajax.post({
      url: "/openPlatform/engineeringContract/underwriteAmount",
      data: { tradeNo: params.tradeNo, ...values },
      self: this,
      success: (res) => {
        if (res) {
          setOfferVisible(false);
          getDetail();
          message.success("操作成功");
        }
      },
      fail: () => {},
    });
  };

  /**
   * @author: huangtaizhu@nongdaxia.com.cn
   * @description: 提交无法承保
   * @param {any} values
   * @return {*}
   */
  const handlerSubmint = (values: any) => {
    ajax.post({
      url: "/openPlatform/engineeringContract/notCovered",
      data: { tradeNo: params.tradeNo, ...values },
      self: this,
      success: (res) => {
        if (res) {
          setUnable(false);
          getDetail();
          message.success("操作成功");
        }
      },
      fail: () => {},
    });
  };

  /**
   * @author: huangtaizhu@nongdaxia.com.cn
   * @description: 更新出函
   * @param {any} values
   * @return {*}
   */
  const handleSubmitUpdate = (values: any) => {
    ajax.post({
      url: "/openPlatform/engineeringContract/updateLetterOfGuarantee",
      data: {
        ...values,
        tradeNo: params.tradeNo,
        elePoliceFile: elePoliceFile,
        insureFile: insureFile,
        // certificate: JSON.stringify(certificate2),
      },
      self: this,
      success: (res) => {
        if (res) {
          getDetail();
          setUpdate(false);
          message.success("操作成功");
        }
      },
      fail: () => {},
    });
  };

  /**
   * @author: huangtaizhu@nongdaxia.com.cn
   * @description: 提交出函
   * @param {any} values
   * @return {*}
   */
  const handleSubmitSend = (values: any) => {
    ajax.post({
      url: "/openPlatform/engineeringContract/sendALetter",
      data: {
        ...values,
        tradeNo: params.tradeNo,
        elePoliceFile: elePoliceFile,
        insureFile: insureFile,
        // certificate: JSON.stringify(certificate1),
      },
      self: this,
      success: (res) => {
        if (res) {
          setSendVisible(false);
          getDetail();
          message.success("操作成功");
        }
      },
      fail: () => {},
    });
  };

  /**
   * @author: huangtaizhu@nongdaxia.com.cn
   * @description: 打包下载
   * @return {*}
   */
  const handleBatch = () => {
    ajax.post({
      url: "/openPlatform/engineeringContract/downloadPackage",
      data: {
        tradeNo: params.tradeNo,
      },
      self: this,
      success: (res) => {
        if (res) {
          window.open(res);
        }
      },
      fail: () => {},
    });
  };

  const getDetail = () => {
    setGuaranteeFiles([]);
    setVoucherFiles([]);
    setRelateFiles([]);
    setBusinessLicense([]);
    setBuildContract([]);
    setIssureFile([]);
    setRecentlyAuditReport([]);
    setRecentlyFinancialReport([]);
    setEnterpriseQualification([]);
    setWinbidNotice([]);
    setBaohanFormatFile([]);
    setOther([]);
    setReplenishFile([]);
    setSignAgreement([]);
    setCancelSignAgreement([]);
    setConstructionPermit([]);
    setDiffFiles([]);
    ajax.post({
      url: "/openPlatform/engineeringContract/detail",
      data: params,
      self: this,
      success: (res) => {
        setData(res || {});
        if (res.qualiRankData) {
          setQualiRankData(res.qualiRankData || {});
        }

        const filesMap = {
          BUSINESS_LICENSE: "企业营业执照",
          BUILD_CONTRACT: "施工合同",
          ISSURE_FILE: "招标文件",
          RECENTLY_AUDIT_REPORT: "近2年审计报告",
          RECENTLY_FINANCIAL_REPORT: "近期财报",
          ENTERPRISE_QUALIFICATION: "企业资质文件",
          WINBID_NOTICE: "中标通知书",
          BAOHAN_FORMAT_FILE: "保函格式文件",
          OTHER: "其他",
          REPLENISH_FILE: "补充材料",
          SIGN_AGREEMENT: "电子签章文件",
          ELE_POLICY_FILE: "保函保单文件",
          ELE_LETTER_FILE: "保函凭证文件",
          CANCEL_SIGN_AGREEMENT: "退保申请单文件",
          CONSTRUCTION_PERMIT: "施工许可证",
          DIFFERENTIATED_STORAGE_OF_SUPPORTING_MATERIALS: "差异化存储佐证材料",
        };

        if (res.files && Object.keys(res.files).length != 0) {
          for (let key in res.files) {
            if (key == "ELE_POLICY_FILE") {
              for (let _key in res.files[key]) {
                setGuaranteeFiles((data: any) => {
                  data.push({
                    time: _key,
                    files: res.files[key][_key],
                  });
                  return data;
                });
              }
            }
            if (key == "ELE_LETTER_FILE") {
              for (let _key in res.files[key]) {
                setVoucherFiles((data: any) => {
                  data.push({
                    time: _key,
                    files: res.files[key][_key],
                  });
                  return data;
                });
              }
            }
            if (key == "SU_ZHE_CAI_LIAO") {
              for (let _key in res.files[key]) {
                setRelateFiles((data: any) => {
                  data.push({
                    time: _key,
                    files: res.files[key][_key],
                  });
                  return data;
                });
              }
            }
            if (key == "BUSINESS_LICENSE") {
              for (let _key in res.files[key]) {
                setBusinessLicense((data: any) => {
                  data.push({
                    time: _key,
                    files: res.files[key][_key],
                  });
                  return data;
                });
              }
            }
            if (key == "BUILD_CONTRACT") {
              for (let _key in res.files[key]) {
                setBuildContract((data: any) => {
                  data.push({
                    time: _key,
                    files: res.files[key][_key],
                  });
                  return data;
                });
              }
            }
            if (key == "ISSURE_FILE") {
              for (let _key in res.files[key]) {
                setIssureFile((data: any) => {
                  data.push({
                    time: _key,
                    files: res.files[key][_key],
                  });
                  return data;
                });
              }
            }
            if (key == "RECENTLY_AUDIT_REPORT") {
              for (let _key in res.files[key]) {
                setRecentlyAuditReport((data: any) => {
                  data.push({
                    time: _key,
                    files: res.files[key][_key],
                  });
                  return data;
                });
              }
            }
            if (key == "RECENTLY_FINANCIAL_REPORT") {
              for (let _key in res.files[key]) {
                setRecentlyFinancialReport((data: any) => {
                  data.push({
                    time: _key,
                    files: res.files[key][_key],
                  });
                  return data;
                });
              }
            }
            if (key == "ENTERPRISE_QUALIFICATION") {
              for (let _key in res.files[key]) {
                setEnterpriseQualification((data: any) => {
                  data.push({
                    time: _key,
                    files: res.files[key][_key],
                  });
                  return data;
                });
              }
            }
            if (key == "WINBID_NOTICE") {
              for (let _key in res.files[key]) {
                setWinbidNotice((data: any) => {
                  data.push({
                    time: _key,
                    files: res.files[key][_key],
                  });
                  return data;
                });
              }
            }
            if (key == "BAOHAN_FORMAT_FILE") {
              for (let _key in res.files[key]) {
                setBaohanFormatFile((data: any) => {
                  data.push({
                    time: _key,
                    files: res.files[key][_key],
                  });
                  return data;
                });
              }
            }
            if (key == "OTHER") {
              for (let _key in res.files[key]) {
                setOther((data: any) => {
                  data.push({
                    time: _key,
                    files: res.files[key][_key],
                  });
                  return data;
                });
              }
            }
            if (key == "REPLENISH_FILE") {
              for (let _key in res.files[key]) {
                setReplenishFile((data: any) => {
                  data.push({
                    time: _key,
                    files: res.files[key][_key],
                  });
                  return data;
                });
              }
            }
            if (key == "SIGN_AGREEMENT") {
              for (let _key in res.files[key]) {
                setSignAgreement((data: any) => {
                  data.push({
                    time: _key,
                    files: res.files[key][_key],
                  });
                  return data;
                });
              }
            }
            if (key == "SIGN_AGREEMENT") {
              for (let _key in res.files[key]) {
                setSignAgreement((data: any) => {
                  data.push({
                    time: _key,
                    files: res.files[key][_key],
                  });
                  return data;
                });
              }
            }
            if (key == "CANCEL_SIGN_AGREEMENT") {
              for (let _key in res.files[key]) {
                setCancelSignAgreement((data: any) => {
                  data.push({
                    time: _key,
                    files: res.files[key][_key],
                  });
                  return data;
                });
              }
            }
            if (key == "CONSTRUCTION_PERMIT") {
              for (let _key in res.files[key]) {
                setConstructionPermit((data: any) => {
                  data.push({
                    time: _key,
                    files: res.files[key][_key],
                  });
                  return data;
                });
              }
            }
            if (key == "DIFFERENTIATED_STORAGE_OF_SUPPORTING_MATERIALS") {
              for (let _key in res.files[key]) {
                setDiffFiles((data: any) => {
                  data.push({
                    time: _key,
                    files: res.files[key][_key],
                  });
                  return data;
                });
              }
            }
          }
        }
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <div>
      <div className="pb-[12]">
        <span className="t-[16] aui-192530 pr-[4]">
          工程合同履约保函订单详情
        </span>
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>订单详情</p>
        <div className={proceedingDetailLess.opers}>
          {data.orderStatusCode == 3 && (
            <Button type="primary" onClick={() => setOfferVisible(true)}>
              承保报价
            </Button>
          )}
          {data.orderStatusCode == 3 && (
            <Button type="primary" onClick={() => setStuffVisible(true)}>
              材料不足
            </Button>
          )}
          {data.orderStatusCode == 3 && (
            <Button type="primary" onClick={() => setUnable(true)}>
              无法承保
            </Button>
          )}
          {data.orderStatusCode == 7 && (
            <Button type="primary" onClick={() => setUnable(true)}>
              无法承保
            </Button>
          )}
          {data.orderStatusCode == 7 && (
            <Button type="primary" onClick={() => setSendVisible(true)}>
              出函
            </Button>
          )}
          {data.orderStatusCode == 9 && (
            <Button type="primary" onClick={() => setUpdate(true)}>
              更新出函
            </Button>
          )}
        </div>
        {data.payVoucher && data.payType == "OFFLINE" && (
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="订单号">{data.tradeNo}</Dglistitem>
            <Dglistitem label="订单状态">{data.orderStatusName}</Dglistitem>
            <Dglistitem label="订单金额（元）">
              <span className={proceedingDetailLess.amount__color}>
                {data.orderAmount}
              </span>
            </Dglistitem>
            <Dglistitem label="提交时间">{data.gmtCreate}</Dglistitem>
            <Dglistitem label="支付时间">{data.payCallbackTime}</Dglistitem>
            <Dglistitem label="出函时间">{data.letterTime}</Dglistitem>
            <Dglistitem label="支付凭证">
              <a href={data.payVoucher} target="_blank">
                支付凭证
              </a>
            </Dglistitem>
          </Dglist>
        )}
        {data.payVoucher && data.payType != "OFFLINE" && (
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="订单号">{data.tradeNo}</Dglistitem>
            <Dglistitem label="订单状态">{data.orderStatusName}</Dglistitem>
            <Dglistitem label="订单金额（元）">
              <span className={proceedingDetailLess.amount__color}>
                {data.orderAmount}
              </span>
            </Dglistitem>
            <Dglistitem label="提交时间">{data.gmtCreate}</Dglistitem>
            <Dglistitem label="支付时间">{data.payCallbackTime}</Dglistitem>
            <Dglistitem label="出函时间">{data.letterTime}</Dglistitem>
            <Dglistitem label="支付凭证">
              <span className={proceedingDetailLess.amount__color}>
                {data.payVoucher}
              </span>
            </Dglistitem>
          </Dglist>
        )}
        {!data.payVoucher && (
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="订单号">{data.tradeNo}</Dglistitem>
            <Dglistitem label="订单状态">{data.orderStatusName}</Dglistitem>
            <Dglistitem label="订单金额（元）">
              <span className={proceedingDetailLess.amount__color}>
                {data.orderAmount}
              </span>
            </Dglistitem>
            <Dglistitem label="提交时间">{data.gmtCreate}</Dglistitem>
            <Dglistitem label="支付时间">{data.payCallbackTime}</Dglistitem>
            <Dglistitem label="出函时间">{data.letterTime}</Dglistitem>
          </Dglist>
        )}
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>投保信息</p>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="投保企业">{data.enterpriseName}</Dglistitem>
          <Dglistitem label="统一社会信用代码">
            {data.enterpriseCode}
          </Dglistitem>
          <Dglistitem label="联系人">{data.concatName}</Dglistitem>
          <Dglistitem label="联系人手机号">{data.concatMobile}</Dglistitem>
          <Dglistitem label="企业地址">{data.enterpriseAddress}</Dglistitem>
          <Dglistitem label="企业资质类型与等级">
            {qualiRankData.qualName && qualiRankData.rankName
              ? `${qualiRankData.qualName}-${qualiRankData.rankName}`
              : ""}
          </Dglistitem>
          <div className="w-[100%]">
            <Dglistitem label="是否反担保" labelWidth="200px">
              {data.counterGuarantee == null ||
              data.counterGuarantee == undefined
                ? ""
                : data.counterGuarantee
                ? "真"
                : "否"}
            </Dglistitem>
          </div>
          <Dglistitem label="项目名称">{data.projectName}</Dglistitem>
          <Dglistitem label="项目编号">{data.projectNo}</Dglistitem>
          <Dglistitem label="项目性质">{data.projectNatureName}</Dglistitem>
          <Dglistitem label="项目地址">{data.projectAddress}</Dglistitem>
          <Dglistitem label="发包人">{data.issueName}</Dglistitem>
          <Dglistitem label="发包人统一信用代码">
            {data.issueUnifyCreditCode}
          </Dglistitem>
          <Dglistitem label="合同金额（元）">
            <span className={proceedingDetailLess.amount__color}>
              {data.contractAmount}
            </span>
          </Dglistitem>
        </Dglist>
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>保函信息</p>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="保函期限">
            {data.insureEffectTimeStart && data.insureEffectTimeEnd
              ? `${data.insureEffectTimeStart}-${data.insureEffectTimeEnd}`
              : ""}
          </Dglistitem>
          <Dglistitem label="保函天数（天）">{data.guaranteeDays}</Dglistitem>
          <Dglistitem label="保函格式要求">
            {data.guaranteeFormatTypeName}
          </Dglistitem>
          <Dglistitem label="担保金额（元）">
            <span className={proceedingDetailLess.amount__color}>
              {data.marginAmount}
            </span>
          </Dglistitem>
          <Dglistitem label="保函编号">{data.guaranteeNum}</Dglistitem>
          <Dglistitem label="保函品种">{data.guaranteeTypeName}</Dglistitem>
          <Dglistitem label="担保机构">{data.financingInstitution}</Dglistitem>
          <Dglistitem label="审核备注">{data.auditRemark}</Dglistitem>
          <div className="w-[100%]">
            {guaranteeFiles.length > 0 && (
              <div className={proceedingDetailLess.filesItem}>
                <div className={proceedingDetailLess.filesLable}>
                  保函保单文件：
                </div>
                <div className={proceedingDetailLess.filesValues}>
                  {guaranteeFiles
                    .sort((a: any, b: any) => {
                      return (
                        new Date(b.time).getTime() - new Date(a.time).getTime()
                      );
                    })
                    .map((item: any) => {
                      return <FileList {...item} key={item}></FileList>;
                    })}
                </div>
              </div>
            )}
          </div>
          <div className="w-[100%]">
            {voucherFiles.length > 0 && (
              <div className={proceedingDetailLess.filesItem}>
                <div className={proceedingDetailLess.filesLable}>
                  保函凭证文件：
                </div>
                <div className={proceedingDetailLess.filesValues}>
                  {voucherFiles
                    .sort((a: any, b: any) => {
                      return (
                        new Date(b.time).getTime() - new Date(a.time).getTime()
                      );
                    })
                    .map((item: any) => {
                      return <FileList {...item} key={item}></FileList>;
                    })}
                </div>
              </div>
            )}
          </div>
          <div className="w-[100%]">
            {relateFiles.length > 0 && (
              <div className={proceedingDetailLess.filesItem}>
                <div className={proceedingDetailLess.filesLable}>
                  诉责材料：
                </div>
                <div className={proceedingDetailLess.filesValues}>
                  {relateFiles
                    .sort((a: any, b: any) => {
                      return (
                        new Date(b.time).getTime() - new Date(a.time).getTime()
                      );
                    })
                    .map((item: any) => {
                      return <FileList {...item} key={item}></FileList>;
                    })}
                </div>
              </div>
            )}
          </div>
        </Dglist>
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>附件</p>
        <div className={proceedingDetailLess.opers}>
          <Button type="primary" onClick={handleBatch}>
            打包下载
          </Button>
        </div>
        <div className="w-[100%]">
          {businessLicense.length > 0 && (
            <div className={proceedingDetailLess.filesItem}>
              <div className={proceedingDetailLess.filesLable}>
                企业营业执照：
              </div>
              {businessLicense
                .sort((a: any, b: any) => {
                  return (
                    new Date(b.time).getTime() - new Date(a.time).getTime()
                  );
                })
                .map((item: any) => {
                  return <FileList {...item} key={item}></FileList>;
                })}
            </div>
          )}
          {data.legalPersonIdCardFront && data.legalPersonIdCardReverse && (
            <div className={proceedingDetailLess.filesItem}>
              <div className={proceedingDetailLess.filesLable}>
                法人身份证：
              </div>
              <div className={proceedingDetailLess.filesValue}>
                <div className={proceedingDetailLess.filesName}>
                  <a
                    href={data.legalPersonIdCardFront}
                    target="_blank"
                    className={proceedingDetailLess.filesActive}
                  >
                    法人身份证正面
                  </a>
                  <a
                    href={data.legalPersonIdCardReverse}
                    target="_blank"
                    className={proceedingDetailLess.filesActive}
                  >
                    法人身份证反面
                  </a>
                </div>
                <div className={proceedingDetailLess.filesActiveTime}>
                  {data.gmtCreate}
                </div>
              </div>
            </div>
          )}
          {buildContract.length > 0 && (
            <div className={proceedingDetailLess.filesItem}>
              <div className={proceedingDetailLess.filesLable}>
                合同文件(或中标通知书)：
              </div>
              {buildContract
                .sort((a: any, b: any) => {
                  return (
                    new Date(b.time).getTime() - new Date(a.time).getTime()
                  );
                })
                .map((item: any) => {
                  return <FileList {...item} key={item}></FileList>;
                })}
            </div>
          )}
          {issureFile.length > 0 && (
            <div className={proceedingDetailLess.filesItem}>
              <div className={proceedingDetailLess.filesLable}>招标文件：</div>
              {issureFile
                .sort((a: any, b: any) => {
                  return (
                    new Date(b.time).getTime() - new Date(a.time).getTime()
                  );
                })
                .map((item: any) => {
                  return <FileList {...item} key={item}></FileList>;
                })}
            </div>
          )}
          {recentlyAuditReport.length > 0 && (
            <div className={proceedingDetailLess.filesItem}>
              <div className={proceedingDetailLess.filesLable}>
                近2年审计报告：
              </div>
              {recentlyAuditReport
                .sort((a: any, b: any) => {
                  return (
                    new Date(b.time).getTime() - new Date(a.time).getTime()
                  );
                })
                .map((item: any) => {
                  return <FileList {...item} key={item}></FileList>;
                })}
            </div>
          )}
          {recentlyFinancialReport.length > 0 && (
            <div className={proceedingDetailLess.filesItem}>
              <div className={proceedingDetailLess.filesLable}>近期财报：</div>
              {recentlyFinancialReport
                .sort((a: any, b: any) => {
                  return (
                    new Date(b.time).getTime() - new Date(a.time).getTime()
                  );
                })
                .map((item: any) => {
                  return <FileList {...item} key={item}></FileList>;
                })}
            </div>
          )}
          {winbidNotice.length > 0 && (
            <div className={proceedingDetailLess.filesItem}>
              <div className={proceedingDetailLess.filesLable}>
                中标通知书：
              </div>
              {winbidNotice
                .sort((a: any, b: any) => {
                  return (
                    new Date(b.time).getTime() - new Date(a.time).getTime()
                  );
                })
                .map((item: any) => {
                  return <FileList {...item} key={item}></FileList>;
                })}
            </div>
          )}
          {other.length > 0 && (
            <div className={proceedingDetailLess.filesItem}>
              <div className={proceedingDetailLess.filesLable}>其他文件：</div>
              {other
                .sort((a: any, b: any) => {
                  return (
                    new Date(b.time).getTime() - new Date(a.time).getTime()
                  );
                })
                .map((item: any) => {
                  return <FileList {...item} key={item}></FileList>;
                })}
            </div>
          )}
          {enterpriseQualification.length > 0 && (
            <div className={proceedingDetailLess.filesItem}>
              <div className={proceedingDetailLess.filesLable}>资质证书：</div>
              {enterpriseQualification
                .sort((a: any, b: any) => {
                  return (
                    new Date(b.time).getTime() - new Date(a.time).getTime()
                  );
                })
                .map((item: any) => {
                  return <FileList {...item} key={item}></FileList>;
                })}
            </div>
          )}
          {baohanFormatFile.length > 0 && (
            <div className={proceedingDetailLess.filesItem}>
              <div className={proceedingDetailLess.filesLable}>
                保函格式要求文件：
              </div>
              {baohanFormatFile
                .sort((a: any, b: any) => {
                  return (
                    new Date(b.time).getTime() - new Date(a.time).getTime()
                  );
                })
                .map((item: any) => {
                  return <FileList {...item} key={item}></FileList>;
                })}
            </div>
          )}
          {replenishFile.length > 0 && (
            <div className={proceedingDetailLess.filesItem}>
              <div className={proceedingDetailLess.filesLable}>补充文件：</div>
              {replenishFile
                .sort((a: any, b: any) => {
                  return (
                    new Date(b.time).getTime() - new Date(a.time).getTime()
                  );
                })
                .map((item: any) => {
                  return <FileList {...item} key={item}></FileList>;
                })}
            </div>
          )}
          {signAgreement.length > 0 && (
            <div className={proceedingDetailLess.filesItem}>
              <div className={proceedingDetailLess.filesLable}>
                电子签章文件：
              </div>
              {signAgreement
                .sort((a: any, b: any) => {
                  return (
                    new Date(b.time).getTime() - new Date(a.time).getTime()
                  );
                })
                .map((item: any) => {
                  return <FileList {...item} key={item}></FileList>;
                })}
            </div>
          )}
        </div>
      </div>
      <div className="text-center mt-[32]">
        <Button
          type="default"
          className="w-160"
          onClick={() => {
            navigate!("/admin/engContract");
          }}
        >
          返回
        </Button>
      </div>

      <Modal
        title="承保报价"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setOfferVisible(false)}
        open={offerVisible}
        footer={false}
        centered
        width={500}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={offerForm}
          onFinish={handlerSubmitOffer}
          initialValues={{
            counterGuarantee: false,
          }}
        >
          <Form.Item
            label="报价"
            name="amount"
            rules={[{ required: true, message: "请输入报价" }]}
          >
            {/* <Input placeholder="请输入报价" /> */}
            <InputNumber
              style={{ width: "100%" }}
              min="0"
              step={0.01}
              controls={false}
            />
          </Form.Item>
          <Form.Item
            label="是否反担保"
            name="counterGuarantee"
            rules={[{ required: true, message: "请选择是否反担保" }]}
          >
            <Radio.Group name="radiogroup" defaultValue={false}>
              <Radio value={false}>否</Radio>
              <Radio value={true}>是</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="审核备注"
            name="auditRemark"
            rules={[{ required: false, message: "请输入审核备注" }]}
          >
            <Input.TextArea rows={4} placeholder="请输入审核备注" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setOfferVisible(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-150 ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="材料不足"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setStuffVisible(false)}
        open={stuffVisible}
        footer={false}
        centered
        width={500}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={stuffForm}
          onFinish={handlerSubmitStuff}
        >
          <Form.Item
            label="审核备注"
            name="auditRemark"
            rules={[{ required: true, message: "请输入审核备注" }]}
          >
            <Input.TextArea rows={4} placeholder="请输入审核备注" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setStuffVisible(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-150 ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="无法承保"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setUnable(false)}
        open={unable}
        footer={false}
        centered
        width={500}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={refForm}
          onFinish={handlerSubmint}
        >
          <Form.Item
            label="审核备注"
            name="auditRemark"
            rules={[{ required: true, message: "请输入审核备注" }]}
          >
            <Input.TextArea rows={4} placeholder="请输入审核备注" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setUnable(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-150 ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="出函"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setSendVisible(false)}
        open={sendVisible}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={sentForm}
          onFinish={handleSubmitSend}
        >
          <Form.Item
            label="保函编号"
            name="insureOrderNo"
            rules={[{ required: true, message: "请输入保函编号" }]}
          >
            <Input placeholder="请输入保函编号" />
          </Form.Item>
          <Form.Item
            label="保函保单文件"
            hasFeedback
            name="elePoliceFile"
            rules={[
              {
                required: true,
                message: "请上传保函保单文件",
              },
            ]}
          >
            <div>
              <Dgupload
                key={Math.random()}
                fileName="保函保单文件"
                type={[".pdf"]}
                setValue={setElePoliceFile}
                value={elePoliceFile}
                button={true}
              ></Dgupload>
              <div className={proceedingDetailLess.suffixBox}>
                支持拓展名：.PDF
              </div>
            </div>
          </Form.Item>
          <Form.Item label="保函凭证文件" name="insureFile">
            <Dgupload
              fileName="保函凭证文件"
              button={true}
              type={[".pdf"]}
              value={insureFile}
              setValue={setInsureFile}
            ></Dgupload>
            <div className={proceedingDetailLess.suffixBox}>
              支持拓展名：.PDF
            </div>
          </Form.Item>
          <Form.Item label="审核备注" name="auditRemark">
            <Input.TextArea rows={4} placeholder="请输入审核备注" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setSendVisible(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-150 ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="更新保函"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setUpdate(false)}
        open={update}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={sentUpdateForm}
          onFinish={handleSubmitUpdate}
        >
          <Form.Item
            label="保函保单文件"
            name="elePoliceFile"
            rules={[{ required: true, message: "请上传保函保单文件" }]}
          >
            <Dgupload
              fileName="保函保单文件"
              type={[".pdf"]}
              setValue={setElePoliceFile}
              value={elePoliceFile}
              button={true}
            ></Dgupload>
          </Form.Item>
          <Form.Item label="保函凭证文件" name="insureFile">
            <Dgupload
              fileName="保函凭证文件"
              type={[".pdf"]}
              setValue={setInsureFile}
              value={insureFile}
              button={true}
            ></Dgupload>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setUpdate(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-150 ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});
