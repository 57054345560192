import * as React from "react";

interface propsType {
	children?: React.ReactElement | null;
	href?: string;
}

export default (props: propsType) => {
	let { children, href } = props;

	return href ? (
		<a href={href} target="_blank">
			{children || "查看"}
		</a>
	) : (
		<span>--</span>
	);
	// return <div>{children}</div>;
};
