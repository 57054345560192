import { useMemo } from "react";
import indexLess from "./css/dg-pasw-intensity.less"

interface PropsType {
  top?: string;
  value?: string | number;
}

export default ((props: PropsType) => {
  const { value, top = "60px" } = props;
  const filterShow = (
    value: string | number | undefined,
    intensity: boolean,
    intensity1: boolean,
    intensity2: boolean
  ) => {
    if (!(intensity && intensity1 && intensity2) && value) {
      return true;
    } else return false;
  };

  const filterIntensity = (value: string | number | undefined) => {
    if (!value) return false;
    const valStr = value.toString()
    return /^.{6,32}$/.test(valStr);
  };

  const filterIntensity1 = (value: string | number | undefined) => {
    if (!value) return false;
    const valStr = value.toString()
    const hasLowercase = /[a-z]/.test(valStr);
    const hasUppercase = /[A-Z]/.test(valStr);
    const hasDigit = /\d/.test(valStr);
    const hasSpecialChar = /[^a-zA-Z0-9]/.test(valStr);
    let charTypes = 0;
    if (hasLowercase) charTypes++;
    if (hasUppercase) charTypes++;
    if (hasDigit) charTypes++;
    if (hasSpecialChar) charTypes++;

    if (charTypes < 2) {
      return false;
    }
    return true;
  };

  const filterIntensity2 = (value: string | number | undefined) => {
    if (!value) return false;
    const valStr = value.toString();
    if (!valStr || valStr.length < 2) return false;
    // 检查是否包含连续的字母或数字
    const sequences = [
      "abcdefghijklmnopqrstuvwxyz",
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      "0123456789",
    ];
    for (const sequence of sequences) {
      for (let i = 0; i <= sequence.length - 3; i++) {
        const subSequence = sequence.substring(i, i + 3);
        if (valStr.includes(subSequence)) {
          return false;
        }
      }
    }
    // a17531
    // 检查是否包含重复的字符序列
    for (let i = 0; i <= valStr.length - 3; i++) {
      if (valStr[i] === valStr[i + 1] && valStr[i] === valStr[i + 2]) {
        return false;
      }
    }
    return true;
  };

  const intensity = useMemo(() => filterIntensity(value), [value]);

  const intensity1 = useMemo(() => filterIntensity1(value), [value]);

  const intensity2 = useMemo(() => filterIntensity2(value), [value]);

  const show = useMemo(
    () => filterShow(value, intensity, intensity1, intensity2),
    [value, intensity, intensity1, intensity2]
  );

  

  /**
   *
   */

  return (
    <div>
      {show && (
        <div
          className="bg-[#303133] rounded-[2] absolute top-[60] left-[0] z-[10] t-[#C0C4CC] py-[6] px-[13]"
          style={{ top }}
        >
          <p className="leading-[18] t-[12]">
            <i
              className={`icon t-[#FFFFFF] ${intensity ? indexLess.active : null}`}
            >
              &#xe62f;
            </i>{" "}
            6-32个字符
          </p>
          <p className="leading-[18] t-[12]">
            <i
              className={`icon t-[#FFFFFF] ${
                intensity1 ? indexLess.active : null
              }`}
            >
              &#xe62f;
            </i>
            至少包含大小写字母、数字、符号中的两种
          </p>
          <p className="leading-[18] t-[12]">
            <i
              className={`icon t-[#FFFFFF] ${
                intensity2 ? indexLess.active : null
              }`}
            >
              &#xe62f;
            </i>
            避免使用连续或重复的字符（如123456、abcdef）
          </p>
          <i className="t-[16] absolute top-[-10] left-[10] t-[#303133] leading-[18] icon">
            &#x100dd;
          </i>
        </div>
      )}
    </div>
  );
});
