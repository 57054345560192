import { Alert, Button, Form, Input } from "antd";
import Header from "../../components/header";
import indexLess from "./css/forgotPasw.less";
import DgCopyright from "../../components/dg-copyright";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { encryptDES } from "../../assets/js/util";
import ajax from "../../assets/js/ajax";
import DgPaswIntensity from "../../components/dg-pasw-intensity";

type AlertType = "success" | "info" | "warning" | "error" | undefined;

export default () => {
  const [useForm] = Form.useForm();
  let [showAlert, setShowAlert] = useState(false);
  let [alert, setAlert] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  let [alertType, setAlertType] = useState<AlertType>("error");
  const navigate = useNavigate();

  const handleClose = () => {
    setShowAlert(false);
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    setNewPassword(allValues.newPassword);
    setConfirmPassword(allValues.confirmPassword);
  };

  const handlerSetPasw = (values: any) => {
    Object.keys(values).forEach((key) => {
      if (key != "mobile") {
        values[key] = encryptDES(values[key]);
      }
    });
    ajax.post({
      url: "/openPlatform/auth/changePassword",
      data: {
        ...values,
        mobile: sessionStorage.getItem("modifyPaswLast_phone"),
      },
      self: this,
      success: (res) => {
        if (res) {
          setShowAlert(true);
          setAlert("密码修改成功");
          setAlertType("success");
          navigate("/login");
        }
      },
      fail: (err) => {
        setShowAlert(true);
        setAlertType("error");
        setAlert(err.msg);
      },
    });
  };

  const password = (rule: any, value: string, callback: any) => {
    if (value === "") {
      callback(new Error("密码格式错误，请重新输入"));
    } else if (
      !/^(?!.*(.)\1{2,})(?=.*[a-zA-Z].*[0-9]|[a-zA-Z].*[!@#$%^&*()_+[\]{};':"\\|,.<>?`~]|[0-9].*[!@#$%^&*()_+[\]{};':"\\|,.<>?`~]).{6,32}$/.test(
        value
      )
    ) {
      callback(new Error("密码格式错误，请重新输入"));
    } else {
      callback();
    }
  };
  const confirmPasswordValidator = (
    rule: any,
    value: string,
    callback: any
  ) => {
    const newPassword = useForm.getFieldValue("newPassword");
    if (value === "") {
      callback(new Error("密码格式错误，请重新输入"));
    } else if (
      !/^(?!.*(.)\1{2,})(?=.*[a-zA-Z].*[0-9]|[a-zA-Z].*[!@#$%^&*()_+[\]{};':"\\|,.<>?`~]|[0-9].*[!@#$%^&*()_+[\]{};':"\\|,.<>?`~]).{6,32}$/.test(
        value
      )
    ) {
      callback(new Error("密码格式错误，请重新输入"));
    } else if (value !== newPassword) {
      callback(new Error("两次密码不一致，请重新输入"));
    } else {
      callback();
    }
  };

  return (
    <div className="relative w-[100%] h-[100vh] min-h-[600] bg-[#FFFFFF] min-w-[1200]">
      <Header></Header>
      <div
        className={`relative flex min-w-[1200] pt-[64] ${indexLess.forgetForm}`}
      >
        <div className="relative mx-[auto] w-[400] pt-[48] rounded-[4] ">
          <div className="text-center t-[24] t-[#192530] leading-[28] tracking-[4] mb-[24] ">
            设置新密码
          </div>
          {showAlert && (
            <div className="pb-[16]">
              <Alert
                showIcon
                closable
                message={alert}
                type={alertType}
                afterClose={handleClose}
                icon={<i className="icon t-[16]">&#xe664;</i>}
                className="!px-[16] !py-[8] !border-[0]"
              />
            </div>
          )}
          <Form
            size="large"
            form={useForm}
            onFinish={handlerSetPasw}
            onValuesChange={onValuesChange}
          >
            <Form.Item
              label=""
              name="newPassword"
              rules={[
                {
                  required: true,
                  validator: (rules, value, callback) =>
                    password(rules, value, callback),
                },
                {},
              ]}
            >
              <div className="relative">
                <Input.Password placeholder="请输入新密码" autoComplete="off" />
                <DgPaswIntensity value={newPassword} />
              </div>
            </Form.Item>
            <Form.Item
              label=""
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  validator: (rules, value, callback) =>
                    confirmPasswordValidator(rules, value, callback),
                },
                {},
              ]}
            >
              <div className="relative">
                <Input.Password placeholder="再次确认密码" autoComplete="off" />
                <DgPaswIntensity value={confirmPassword} />
              </div>
            </Form.Item>
            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                className={indexLess.login_button}
              >
                下一步
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <DgCopyright
        style={{ position: "absolute", bottom: "30px" }}
        paddingTop="0px"
        paddingBottom="0px"
      />
    </div>
  );
};
