import { ReactNode, memo, useEffect, useState } from "react";
import Header from "../../components/header";
import indexLess from "./css/index.less";
import layoutLess from "../../layout.less";
import ajax from "../../assets/js/ajax";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
// import { SearchOutlined } from "@ant-design/icons";
import help1 from "../../assets/img/help1.png";
import help2 from "../../assets/img/help2.png";
import help3 from "../../assets/img/help3.png";
import Search from "./components/search";
import { createPortal } from "react-dom";
import DgCopyright from "../../components/dg-copyright";

export default memo(() => {
  const navigate = useNavigate();

  const [info, setInfo] = useState({
    realName: "",
    balance: 0,
  });

  const [list, setList] = useState<any[]>([]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const getCurrentInfo = () => {
    ajax.post({
      url: "/openPlatform/user/current",
      data: {},
      self: this,
      success: (res) => {
        localStorage.setItem("current", JSON.stringify(res));
        setInfo(res.user || {});
      },
      fail: () => {
        // navigate("/");
      },
    });
  };

  const goBusiness = (path: string) => {
    if ((info.realName && info.realName.length > 0) || path == "/forgotPasw") {
      navigate(path);
    } else {
      navigate("/login");
    }
  };

  const goDetail = (id: number | string, classifyId: number | string) => {
    navigate!("/help/detail", { state: { id, classifyId } });
  };

  const getList = () => {
    ajax.post({
      url: "/openPlatform/support/list",
      data: {},
      self: this,
      success: (res) => {
        setList([...res]);
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    getCurrentInfo();
    getList();
  }, []);

  return (
    <div className="relative h-[100vh] min-h-[750]">
      <Header></Header>
      <div className={`${indexLess.container} w-[1200] mx-[auto]`}>
        <div className={indexLess.head}>
          <div className="t-[24] aui-303133">常见问题</div>
          <Button
            type="primary"
            icon={
              <i className="icon t-[14] mr-[4]">&#xe719;</i>
            }
            onClick={() => setIsModalOpen(true)}
          >
            搜索问题
          </Button>
        </div>
        <div className={indexLess.cardBox}>
          {list.map((item) => {
            return (
              <div className={indexLess.cardItem}>
                <div className={indexLess.cardTitle}>{item.classifyName}</div>
                {item.details.map((curr: any) => {
                  return (
                    <div
                      className={indexLess.cardItemBox}
                      onClick={() => goDetail(curr.id, item.classifyId)}
                    >
                      {curr.title}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className={indexLess.head2}>
          <div className="t-[24] aui-303133">账号相关</div>
        </div>
        <div className={indexLess.cardBox}>
          <div
            className={indexLess.cardItem2}
            onClick={() => goBusiness("/forgotPasw")}
          >
            <div className={indexLess.cardIcon}>
              <img src={help1} alt="" />
            </div>
            <div className={indexLess.cardMsg}>
              <div
                className={`${indexLess.cardMsgTitle} t-[16] aui-303133 leading-[26]`}
              >
                找回密码
              </div>
              <div className="t-[14] aui-909399">
                忘记登录密码时，通过注册的手机号快速找回密码
              </div>
            </div>
          </div>
          <div
            className={indexLess.cardItem2}
            onClick={() => goBusiness("/admin")}
          >
            <div className={indexLess.cardIcon}>
              <img src={help2} alt="" />
            </div>
            <div className={indexLess.cardMsg}>
              <div
                className={`${indexLess.cardMsgTitle} t-[16] aui-303133 leading-[26]`}
              >
                修改联系人手机号
              </div>
              <div className="t-[14] aui-909399">
                手机号涉及企业认证、充值等关键业务，保证账号安全
              </div>
            </div>
          </div>
          <div
            className={indexLess.cardItem2}
            onClick={() => goBusiness("/admin/company")}
          >
            <div className={indexLess.cardIcon}>
              <img src={help3} alt="" />
            </div>
            <div className={indexLess.cardMsg}>
              <div
                className={`${indexLess.cardMsgTitle} t-[16] aui-303133 leading-[26]`}
              >
                企业认证
              </div>
              <div className="t-[14] aui-909399">
                金融机构账号需要进行企业认证，才能进行充值
              </div>
            </div>
          </div>
        </div>
      </div>
      <DgCopyright paddingTop="30px" paddingBottom="30px" />

      <div className={layoutLess.contact_wrap}>
        <div className={layoutLess.contact}>
          <i className="icon aui-color-ff t-[30]">&#xe601;</i>
        </div>
        <div className={layoutLess.contact_info}>
          <p className="t-[16]">客服热线：400-816-1767</p>
          <p className="pt-[6] t-[16]">
            服务时间：工作日9:00-18:00
          </p>
        </div>
      </div>

      {createPortal(
        (
          <Search
            visible={isModalOpen}
            close={() => setIsModalOpen(false)}
            clickItem={(id, classifyId) => goDetail(id, classifyId)}
          ></Search>
        ) as ReactNode,
        document.getElementById("root") as Element
      )}
    </div>
  );
});
