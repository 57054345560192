import indexLess from "./css/bill.less";
import { Table, Button, Form, Pagination, Input, Select } from "antd";
import type { FormInstance } from "antd";

import type { ColumnsType } from "antd/es/table";
import Dgform from "../../components/dg-form";
import React, { useState, useEffect, memo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import type { NavigateFunction } from "react-router-dom";
import { itmeType } from "../../types";
import ajax from "../../assets/js/ajax";

import EmptyPage from "../../components/emptyPage";
import { hasPermission } from "../../assets/js/hasPermission";

const { Option } = Select;
const { TextArea } = Input;
let navigate: NavigateFunction | null = null;
interface DataType {
  key: React.Key;
  title: string;
  dataIndex: string;
  align: string;
}

export default memo(() => {
  navigate = useNavigate();
  let [refForm] = Form.useForm();
  let [allInvoiceType, setAllInvoiceType] = useState<any[]>([]);
  const columns: ColumnsType<DataType> = [
    {
      title: "产品",
      key: "product",
      dataIndex: "product",
      align: "center",
    },

    {
      title: "结算时间",
      dataIndex: "gmtCreate",
      key: "gmtCreate",
      align: "center",
    },
    {
      title: "优惠（元）",
      dataIndex: "discountAmount",
      key: "discountAmount",
      align: "center",
    },

    {
      title: "实付（元）",
      dataIndex: "settledAmount",
      key: "settledAmount",
      align: "center",
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
      align: "center",
    },
  ];
  let [totalItems, setTotalItems] = useState<number>(0);
  const formRef = useRef<{
    getFormRef: () => FormInstance<any>;
    getValues: () => any;
  }>();

  let [formItems, setFormItems] = useState<itmeType[]>([
    {
      label: "产品名称",
      type: "input",
      key: "productName",
      des: "请输入产品名称",
    },
    {
      label: "账单日期",
      type: "date",
      model: [],
      key: "billBeginTime,billEndTime",
      des: "请选择账单起始日期",
    },
  ]);
  let [data, setData] = useState<any>([]);

  // 地址： http://jinfu.baohan.com:8080/doc.html#/default/%E5%BC%80%E6%94%BE%E5%B9%B3%E5%8F%B0-%E4%BA%A7%E5%93%81%E8%B4%A6%E5%8D%95/listUsingGET_9
  // 接口： /openPlatform/billDetail/list
  interface billDetailList {
    pageNo: number | null; // 页数
    pageSize: number | null; // 条数
    billBeginTime?: string; // 账单起始日期
    billEndTime?: string; // 账单结束日期
    productName?: string; // 产品名称
    sortDirection?: string; // 排序方向
    sortField?: string; // 排序字段
  }

  let [formData, setFormData] = useState<billDetailList>({
    pageNo: 1, // 页数
    pageSize: 10, // 条数
    billBeginTime: "", // 账单起始日期
    billEndTime: "", // 账单结束日期
    productName: "", // 产品名称
    sortDirection: "", // 排序方向
    sortField: "", // 排序字段
  });

  /**
   * @author 伟健 / 张
   * @name 搜索
   * @param
   * @return
   */
  let handelSeach = (values: any) => {
    setFormData({
      pageNo: 1,
      pageSize: 10,
      ...values,
    });
  };

  /**
   * @author 伟健 / 张
   * @name 列表
   * @param
   * @return
   */
  let getList = (data?: any) => {
    ajax.get({
      url: "/openPlatform/billDetail/list",
      params: Object.assign(formData, data || {}),
      self: this,
      success: (res) => {
        setData(() => {
          return res.result.map((e: any, i: number) => {
            return { ...e, key: i };
          });
        });
        setTotalItems(res.totalItems);
      },
      fail: () => {},
    });
  };

  /**
   * @author 伟健 / 张
   * @name 分页
   * @param
   * @return
   */
  let handelPageChange = (pageNo: number, pageSize: number) => {
    setFormData({
      pageNo,
      pageSize,
      ...formRef.current?.getValues(),
    });
  };
  /**
   * @author 伟健 / 张
   * @name 导出表格
   * @param
   * @return
   */
  let handlerExport = () => {
    ajax.get({
      url: "/openPlatform/billDetail/detailExport",
      params: Object.assign(formData, formRef.current?.getValues() || {}),
      self: this,
      success: (res) => {
        // console.log(res);
        window.open(res);
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    if (hasPermission("openPlatform:billDetail:list")) {
    getList();
    }
  }, [formData]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {hasPermission("openPlatform:billDetail:list") ? (
        <div className="">
          <div className="pb-[12]">
            <span className="t-[16] aui-192530 pr-[4]">
              充值订单
            </span>
          </div>
          <div className={`aui-bg-white ${indexLess.wrap} `}>
            <div className={` ${indexLess.bor_bttom}  p-[26]`}>
              <Dgform ref={formRef} items={formItems} handelSeach={handelSeach}>
                <Form.Item className="ml-[auto] mr-[0]">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="mr-[10]"
                  >
                    搜索
                  </Button>
                  <Button type="primary" onClick={() => handlerExport()}>
                    <i className="icon">&#xe616;</i> 导出表格
                  </Button>
                </Form.Item>
              </Dgform>
            </div>
            <div className="p-[26]">
              <Table
                rowSelection={{ type: "checkbox" }}
                columns={columns}
                pagination={false}
                dataSource={data}
              />
              <div className="text-center pt-[24]">
                <Pagination
                  onChange={handelPageChange}
                  total={totalItems}
                  showTotal={(total) => `总条数 ${total}`}
                ></Pagination>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyPage />
      )}
    </div>
  );
});
