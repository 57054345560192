import Dgupload from "../../components/dg-upload";
import { Button, Form, Image, Input, Modal, Tabs, Tag, message } from "antd";
import proceedingDetailLess from "./css/proceedingDetail.less";
import { memo, useEffect, useState } from "react";
import Dglist from "../../components/dg-list";
import Dglistitem from "../../components/dg-list-item";
import idCardFront from "../../assets/img/idCardFront.png";
import idCardBack from "../../assets/img/idCardBack.png";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import ajax from "../../assets/js/ajax";
import FileList from "./components/fileList";

interface Applicant {
  applicantPeopleType?: number;
  applicantPeopleTypeName?: string;
  applicantName?: string;
  certificateTypeName?: string;
  applicantCertificateNumber?: string;
  applicantLinkerMobile?: string;
  applicantAddress?: string;
  idcardFrontPic?: string;
  idcardBackPic?: string;
  businessLicense?: string;
}

interface Respondent {
  respondentPeopleType?: number;
  respondentPeopleTypeName?: string;
  respondentName?: string;
  respondentCertificateNumber?: string;
  idcardFrontPic?: string;
  idcardBackPic?: string;
  businessLicense?: string;
}

let navigate: NavigateFunction | null = null;

export default memo(() => {
  navigate = useNavigate();
  const params = useLocation().state as {
    tradeNo: string;
  };

  const [data, setData] = useState({
    tradeNo: "",
    msg: "",
    amount: "",
    gmtCreate: "", //提交时间
    payCallbackTime: "", //支付时间
    insureSuccessTime: "", //出函时间
    payVoucher: "", //支付凭证
    attorneyName: "", //代理律师
    attorneyPhone: "", // 代理律师联系电话
    courtName: "", //法院名称
    preserveTypeName: "", //保全类别名称
    preserveFormName: "", //保全类型名称
    caseNum: "", //案件号
    caseReasons: "", //案由
    subjectAmount: "", //标的金额
    insureOrderNo: "", //保函编号
    guaranteeCategoryName: "", //保函品种名称
    financingInstitution: "", //金融机构名称
    auditRemark: "", //保函编号
    status: "",
    payType: "",
  });

  const [refForm] = Form.useForm();
  const [unable, setUnable] = useState<boolean>(false);

  const [sentForm] = Form.useForm();
  const [sentUpdateForm] = Form.useForm();
  const [sendVisible, setSendVisible] = useState<boolean>(false);

  const [update, setUpdate] = useState<boolean>(false);

  const [applicants, setApplicants] = useState<Applicant[]>([]);

  const [applicant, setApplicant] = useState<Applicant>({});

  const [elePoliceFile, setElePoliceFile] = useState<string>("");
  const [insureFile, setInsureFile] = useState<string>("");
  const [certificate1, setCertificate1] = useState<any[]>([]);
  const [certificate2, setCertificate2] = useState<any[]>([]);

  const [baohanDocumentList, setBaohanDocumentList] = useState<any[]>([]);
  const [documentGroups, setDocumentGroups] = useState<any[]>([]);

  const [respondents, setRespondents] = useState<Respondent[]>([]);
  const [respondent, setRespondent] = useState<Respondent>({});

  const handleSwtichApplicant = (index: string) => {
    const cur = applicants[Number(index) - 1];
    setApplicant(cur);
  };

  const handleSwtichRespondent = (index: string) => {
    const cur = respondents[Number(index) - 1];
    setRespondent(cur);
  };

  /**
   * @author: huangtaizhu@nongdaxia.com.cn
   * @description: 提交无法承保
   * @param {any} values
   * @return {*}
   */
  const handlerSubmint = (values: any) => {
    ajax.post({
      url: "/openPlatform/lawsuit/notCovered",
      data: { tradeNo: params.tradeNo, ...values },
      self: this,
      success: (res) => {
        if (res) {
          setUnable(false);
          getDetail();
          message.success("操作成功");
        }
      },
      fail: () => {},
    });
  };

  const handleChangeCertFile1 = (e: any) => {
    if (e.type == "del") {
      setCertificate1(certificate1.filter((curr) => curr.path != e.res));
    } else {
      setCertificate1((data: any) => {
        data.push({ path: e, enable: true });
        return data;
      });
    }
  };

  const handleChangeCertFile2 = (e: any) => {
    if (e.type == "del") {
      setCertificate2(certificate2.filter((curr) => curr.path != e.res));
    } else {
      setCertificate2((data: any) => {
        data.push({ path: e, enable: true });
        return data;
      });
    }
  };

  /**
   * @author: huangtaizhu@nongdaxia.com.cn
   * @description: 更新出函
   * @param {any} values
   * @return {*}
   */
  const handleSubmitUpdate = (values: any) => {
    ajax.post({
      url: "/openPlatform/lawsuit/updateLetterOfGuarantee",
      data: {
        ...values,
        tradeNo: params.tradeNo,
        certificate: JSON.stringify(certificate2),
      },
      self: this,
      success: (res) => {
        if (res) {
          getDetail();
          setUpdate(false);
          message.success("操作成功");
        }
      },
      fail: () => {},
    });
  };

  /**
   * @author: huangtaizhu@nongdaxia.com.cn
   * @description: 提交出函
   * @param {any} values
   * @return {*}
   */
  const handleSubmitSend = (values: any) => {
    ajax.post({
      url: "/openPlatform/lawsuit/sendALetter",
      data: {
        ...values,
        tradeNo: params.tradeNo,
        elePoliceFile: elePoliceFile,
        insureFile: insureFile,
        certificate: JSON.stringify(certificate1),
      },
      self: this,
      success: (res) => {
        if (res) {
          setSendVisible(false);
          getDetail();
          message.success("操作成功");
        }
      },
      fail: () => {},
    });
  };

  /**
   * @author: huangtaizhu@nongdaxia.com.cn
   * @description: 打包下载
   * @return {*}
   */
  const handleBatch = () => {
    ajax.post({
      url: "/openPlatform/lawsuit/downloadPackage",
      data: {
        tradeNo: params.tradeNo,
      },
      self: this,
      success: (res) => {
        if (res) {
          window.open(res);
        }
      },
      fail: () => {},
    });
  };

  const getDetail = () => {
    setApplicants([]);
    setRespondents([]);
    setBaohanDocumentList([]);
    setDocumentGroups([]);
    ajax.post({
      url: "/openPlatform/lawsuit/detail",
      data: params,
      self: this,
      success: (res) => {
        setData(res.order || {});
        if (res.baohanDocumentList && res.baohanDocumentList.length) {
          setBaohanDocumentList((data) => {
            res.baohanDocumentList.forEach((el: any) => {
              data.push({ ...el });
            });
            return data;
          });
        }
        if (res.documentGroups && res.documentGroups.length) {
          setDocumentGroups((data) => {
            res.documentGroups.forEach((el: any) => {
              data.push({ ...el });
            });
            return data;
          });
        }
        if (res.order.applicantList && res.order.applicantList.length) {
          setApplicants((data) => {
            res.order.applicantList.map((el: any) => {
              data.push({ ...el });
            });
            return data;
          });
          setApplicant(res.order.applicantList[0]);
        }

        if (res.order.respondentList && res.order.respondentList.length) {
          setRespondents((data) => {
            res.order.respondentList.map((el: any) => {
              data.push({ ...el });
            });
            return data;
          });
          setRespondent(res.order.respondentList[0]);
        }
      },
      fail: () => {},
    });
  };

  const handlePush = () => {
    Modal.confirm({
      title: "推送投保",
      content: (
        <div>
          <p>确定推送投保吗?</p>
        </div>
      ),
      onOk() {
        ajax.post({
          url: "/openPlatform/lawsuit/applyPush",
          data: {
            tradeNo: params.tradeNo,
          },
          self: this,
          success: (res) => {
            console.log(res);
            message.success("操作成功");
          },
          fail: (e) => {
            console.log(e);
            // message.error(e.msg || "操作失败");
          },
        });
      },
    });
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <div>
      <div className="pb-[12]">
        <span className="t-[16] aui-192530 pr-[4]">
          法院诉讼保函订单详情
        </span>
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>订单详情</p>
        <div className={proceedingDetailLess.opers}>
          {data.status == "5" && (
            <Button type="primary" onClick={() => setUnable(true)}>
              无法承保
            </Button>
          )}
          {data.status == "5" && (
            <Button type="primary" onClick={() => setSendVisible(true)}>
              出函
            </Button>
          )}
          {data.status == "5" && (
            <Button type="primary" onClick={() => handlePush()}>
              推送投保
            </Button>
          )}
          {data.status == "6" && (
            <Button type="primary" onClick={() => setUpdate(true)}>
              更新出函
            </Button>
          )}
        </div>
        {data.payVoucher && data.payType == "OFFLINE" && (
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="订单号">{data.tradeNo}</Dglistitem>
            <Dglistitem label="订单状态">{data.msg}</Dglistitem>
            <Dglistitem label="订单金额（元）">
              <span className={proceedingDetailLess.amount__color}>
                {data.amount}
              </span>
            </Dglistitem>
            <Dglistitem label="提交时间">{data.gmtCreate}</Dglistitem>
            <Dglistitem label="出函时间">{data.insureSuccessTime}</Dglistitem>
            <Dglistitem label="支付凭证">
              <a href={data.payVoucher} target="_blank">
                支付凭证
              </a>
            </Dglistitem>
          </Dglist>
        )}
        {data.payVoucher && data.payType != "OFFLINE" && (
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="订单号">{data.tradeNo}</Dglistitem>
            <Dglistitem label="订单状态">{data.msg}</Dglistitem>
            <Dglistitem label="订单金额（元）">
              <span className={proceedingDetailLess.amount__color}>
                {data.amount}
              </span>
            </Dglistitem>
            <Dglistitem label="提交时间">{data.gmtCreate}</Dglistitem>
            <Dglistitem label="出函时间">{data.insureSuccessTime}</Dglistitem>
            <Dglistitem label="支付凭证">
              <span className={proceedingDetailLess.amount__color}>
                {data.payVoucher}
              </span>
            </Dglistitem>
          </Dglist>
        )}
        {!data.payVoucher && (
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="订单号">{data.tradeNo}</Dglistitem>
            <Dglistitem label="订单状态">{data.msg}</Dglistitem>
            <Dglistitem label="订单金额（元）">
              <span className={proceedingDetailLess.amount__color}>
                {data.amount}
              </span>
            </Dglistitem>
            <Dglistitem label="提交时间">{data.gmtCreate}</Dglistitem>
            <Dglistitem label="出函时间">{data.insureSuccessTime}</Dglistitem>
          </Dglist>
        )}
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <div className={proceedingDetailLess.title__box}>
          <p className={proceedingDetailLess.title}>申请人</p>
          <div className="">
            <Tabs
              defaultActiveKey="1"
              type="card"
              size="small"
              onChange={handleSwtichApplicant}
              items={applicants.map((_, i) => {
                const id = String(i + 1);
                return {
                  label: `申请人${id}`,
                  key: id,
                };
              })}
            />
            {/* {applicants.map((item, index) => {
              return (
                <Tag onClick={() => handleSwtichApplicant(index)}>
                  申请人{index + 1}
                </Tag>
              );
            })} */}
          </div>
        </div>
        {applicant.applicantPeopleType == 1 && (
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="类型">
              {applicant.applicantPeopleTypeName || ""}
            </Dglistitem>
            <Dglistitem label="姓名">
              {applicant.applicantName || ""}
            </Dglistitem>
            <Dglistitem label="证件类型">
              {applicant.certificateTypeName || ""}
            </Dglistitem>
            <Dglistitem label="证件号码">
              {applicant.applicantCertificateNumber || ""}
            </Dglistitem>
            <Dglistitem label="联系手机">
              {applicant.applicantLinkerMobile || ""}
            </Dglistitem>
            <Dglistitem label="地址">
              {applicant.applicantAddress || ""}
            </Dglistitem>
            <Dglistitem label="身份证">
              <div className={proceedingDetailLess.imageBox}>
                <Image
                  width={126}
                  height={95}
                  src={applicant.idcardFrontPic || ""}
                  fallback={idCardFront}
                />
                <Image
                  width={126}
                  height={95}
                  src={applicant.idcardBackPic || ""}
                  fallback={idCardBack}
                />
              </div>
            </Dglistitem>
            <Dglistitem label="营业执照">
              <div>
                <Image
                  width={126}
                  height={95}
                  src={applicant.businessLicense || ""}
                />
              </div>
            </Dglistitem>
          </Dglist>
        )}
        {applicant.applicantPeopleType != 1 && (
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="类型">
              {applicant.applicantPeopleTypeName || ""}
            </Dglistitem>
            <Dglistitem label="姓名">
              {applicant.applicantName || ""}
            </Dglistitem>
            <Dglistitem label="证件类型">
              {applicant.certificateTypeName || ""}
            </Dglistitem>
            <Dglistitem label="证件号码">
              {applicant.applicantCertificateNumber || ""}
            </Dglistitem>
            <Dglistitem label="联系手机">
              {applicant.applicantLinkerMobile || ""}
            </Dglistitem>
            <Dglistitem label="地址">
              {applicant.applicantAddress || ""}
            </Dglistitem>
            <Dglistitem label="身份证">
              <div className={proceedingDetailLess.imageBox}>
                <Image
                  width={126}
                  height={95}
                  src={applicant.idcardFrontPic || ""}
                  fallback={idCardFront}
                />
                <Image
                  width={126}
                  height={95}
                  src={applicant.idcardBackPic || ""}
                  fallback={idCardBack}
                />
              </div>
            </Dglistitem>
          </Dglist>
        )}
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <div className={proceedingDetailLess.title__box}>
          <p className={proceedingDetailLess.title}>被申请人</p>
          <div className="">
            <Tabs
              defaultActiveKey="1"
              type="card"
              size="small"
              onChange={handleSwtichRespondent}
              items={respondents.map((_, i) => {
                const id = String(i + 1);
                return {
                  label: `被申请人${id}`,
                  key: id,
                };
              })}
            />
            {/* {respondents.map((item, index) => {
              return (
                <Tag onClick={() => handleSwtichRespondent(index)}>
                  被申请人{index + 1}
                </Tag>
              );
            })} */}
          </div>
        </div>
        {respondent.respondentPeopleType == 1 && (
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="类型">
              {respondent.respondentPeopleTypeName || ""}
            </Dglistitem>
            <Dglistitem label="姓名">
              {respondent.respondentName || ""}
            </Dglistitem>
            <div className="w-[100%]">
              <Dglistitem label="证件号码" column={1} labelWidth="200px">
                {respondent.respondentCertificateNumber || ""}
              </Dglistitem>
            </div>
            <Dglistitem label="身份证">
              <div className={proceedingDetailLess.imageBox}>
                <Image
                  width={126}
                  height={95}
                  src={respondent.idcardFrontPic || ""}
                  fallback={idCardFront}
                />
                <Image
                  width={126}
                  height={95}
                  src={respondent.idcardBackPic || ""}
                  fallback={idCardBack}
                />
              </div>
            </Dglistitem>
            <Dglistitem label="营业执照">
              <div>
                <Image
                  width={126}
                  height={95}
                  src={respondent.businessLicense || ""}
                />
              </div>
            </Dglistitem>
          </Dglist>
        )}
        {respondent.respondentPeopleType != 1 && (
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="类型">
              {respondent.respondentPeopleTypeName || ""}
            </Dglistitem>
            <Dglistitem label="姓名">
              {respondent.respondentName || ""}
            </Dglistitem>
            <Dglistitem label="证件号码">
              {respondent.respondentCertificateNumber || ""}
            </Dglistitem>
            <div className="w-[100%]">
              <Dglistitem label="身份证" column={1} labelWidth="200px">
                <div className={proceedingDetailLess.imageBox}>
                  <Image
                    width={126}
                    height={95}
                    src={respondent.idcardFrontPic || ""}
                    fallback={idCardFront}
                  />
                  <Image
                    width={126}
                    height={95}
                    src={respondent.idcardBackPic || ""}
                    fallback={idCardBack}
                  />
                </div>
              </Dglistitem>
            </div>
          </Dglist>
        )}
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>代理律师</p>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="姓名">{data.attorneyName}</Dglistitem>
          <Dglistitem label="联系电话">{data.attorneyPhone}</Dglistitem>
        </Dglist>
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>法院信息</p>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="法院名称">{data.courtName}</Dglistitem>
          <Dglistitem label="保全类型">{data.preserveFormName}</Dglistitem>
          <Dglistitem label="案件号">{data.caseNum}</Dglistitem>
          <Dglistitem label="保全类别">{data.preserveTypeName}</Dglistitem>
          <Dglistitem label="案由">{data.caseReasons}</Dglistitem>
          <Dglistitem label="标的金额">{data.subjectAmount}</Dglistitem>
        </Dglist>
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>保函信息</p>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="保函编号">{data.insureOrderNo}</Dglistitem>
          <Dglistitem label="保函品种">{data.guaranteeCategoryName}</Dglistitem>
          <Dglistitem label="担保机构">{data.financingInstitution}</Dglistitem>
          <Dglistitem label="审核备注">{data.auditRemark}</Dglistitem>
          <div className="w-[100%]">
            {baohanDocumentList.length > 0 &&
              baohanDocumentList.map((item) => {
                return (
                  <div className="w-[100%]" key={item}>
                    <div className={proceedingDetailLess.filesItem}>
                      <div className={proceedingDetailLess.filesLable}>
                        {item.typeName}：
                      </div>
                      <div className={proceedingDetailLess.filesValues}>
                        {item.attachments
                          .sort((a: any, b: any) => {
                            return (
                              new Date(b.time).getTime() -
                              new Date(a.time).getTime()
                            );
                          })
                          .map((curr: any) => {
                            return (
                              <FileList
                                {...curr}
                                files={curr.fileUrls}
                                key={curr}
                              ></FileList>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </Dglist>
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>附件</p>
        <div className={proceedingDetailLess.opers}>
          <Button type="primary" onClick={handleBatch}>
            打包下载
          </Button>
        </div>
        <div className="w-[100%]">
          {documentGroups.length > 0 &&
            documentGroups.map((item) => {
              return (
                <div className="w-[100%]" key={item}>
                  <div className={proceedingDetailLess.filesItem}>
                    <div className={proceedingDetailLess.filesLable}>
                      {item.typeName}：
                    </div>
                    <div className={proceedingDetailLess.filesValues}>
                      {item.attachments
                        .sort((a: any, b: any) => {
                          return (
                            new Date(b.time).getTime() -
                            new Date(a.time).getTime()
                          );
                        })
                        .map((curr: any) => {
                          return (
                            <FileList
                              {...curr}
                              files={curr.fileUrls}
                              key={curr}
                            ></FileList>
                          );
                        })}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="text-center mt-[32]">
        <Button
          type="default"
          className="w-160"
          onClick={() => {
            navigate!("/admin/procceedings");
          }}
        >
          返回
        </Button>
      </div>

      <Modal
        title="无法承保"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setUnable(false)}
        open={unable}
        footer={false}
        centered
        width={500}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={refForm}
          onFinish={handlerSubmint}
        >
          <Form.Item
            label="审核备注"
            name="auditRemark"
            rules={[{ required: true, message: "请输入审核备注" }]}
          >
            <Input.TextArea rows={4} placeholder="请输入审核备注" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setUnable(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-150 ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="出函"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setSendVisible(false)}
        open={sendVisible}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={sentForm}
          onFinish={handleSubmitSend}
        >
          <Form.Item
            label="保函编号"
            name="insureOrderNo"
            rules={[{ required: true, message: "请输入保函编号" }]}
          >
            <Input placeholder="请输入保函编号" />
          </Form.Item>
          <Form.Item
            label="保函保单文件"
            hasFeedback
            name="elePoliceFile"
            rules={[
              {
                required: true,
                message: "请上传保函保单文件",
              },
            ]}
          >
            <div>
              <Dgupload
                fileName="保函保单文件"
                button={true}
                type={[".pdf"]}
                value={elePoliceFile}
                setValue={setElePoliceFile}
              ></Dgupload>
              <div className={proceedingDetailLess.suffixBox}>
                支持拓展名：.PDF
              </div>
            </div>
          </Form.Item>
          <Form.Item label="保函凭证文件" name="insureFile">
            <Dgupload
              fileName="保函凭证文件"
              button={true}
              type={[".pdf"]}
              value={insureFile}
              setValue={setInsureFile}
            ></Dgupload>
            <div className={proceedingDetailLess.suffixBox}>
              支持拓展名：.PDF
            </div>
          </Form.Item>
          <Form.Item label="诉责材料" name="certificate">
            <Dgupload
              fileName="诉责材料"
              limit={10}
              type={[]}
              multiple={true}
              button={true}
              onChange={handleChangeCertFile1}
            ></Dgupload>
          </Form.Item>
          <Form.Item label="审核备注" name="auditRemark">
            <Input.TextArea rows={4} placeholder="请输入审核备注" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setSendVisible(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-150 ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="更新保函"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setUpdate(false)}
        open={update}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={sentUpdateForm}
          onFinish={handleSubmitUpdate}
        >
          <Form.Item label="保函保单文件" name="elePoliceFile">
            <Dgupload
              fileName="保函保单文件"
              type={[".pdf"]}
              setValue={setElePoliceFile}
              value={elePoliceFile}
              button={true}
            ></Dgupload>
          </Form.Item>
          <Form.Item label="保函凭证文件" name="insureFile">
            <Dgupload
              fileName="保函凭证文件"
              type={[".pdf"]}
              setValue={setInsureFile}
              value={insureFile}
              button={true}
            ></Dgupload>
          </Form.Item>
          <Form.Item label="诉责材料" name="certificate">
            <Dgupload
              fileName="诉责材料"
              type={[]}
              limit={10}
              multiple={true}
              button={true}
              onChange={handleChangeCertFile2}
            ></Dgupload>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setUpdate(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-150 ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});
