import uploadLess from "./css/dg-upload.less";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile } from "antd/es/upload/interface";
import { Button, message, Input } from "antd";
import { useState, useEffect } from "react";
import type { Dispatch, SetStateAction, ChangeEvent } from "react";
import ajax from "../assets/js/ajax";
import React from "react";
// useState
interface propsType {
  fileName?: string;
  limit?: number;
  multiple?: boolean;
  button?: boolean;
  type?: string[];
  value?: string;
  // model?: string;
  children?: React.ReactNode;
  setValue?: Dispatch<SetStateAction<string>>;
  onChange?: (val: any) => void;
}

export default (props: propsType) => {
  let {
    fileName,
    limit = 1,
    multiple = false,
    button,
    children,
    type = [".jpg", ".jpeg", ".png"],
    setValue,
    value,
    onChange,
  } = props;
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  let changeFile = (e: ChangeEvent<HTMLInputElement>) => {
    var file = e.target.files![0]; //获取上传的文件
    console.log('file+++++++++++++++++++++',file);
    console.log('value+++++++++++++++++++++',value);
    if (!file) {
      
      return;
    };
    if (!beforeUpload(file)) {
      return;
    }
    var formData = new FormData();
    formData.append("fileName", file);
    ajax.uploadFile({
      url: "/openPlatform/upload",
      data: formData,
      self: this,
      success: (res) => {
        if (res) {
          
          setImageUrl(res);
          multiple &&
            setImageUrls((data: string[]) => {
              data.push(res);
              return data;
            });
          onChange && onChange(res);
          setValue && setValue(res);
        }
      },
      fail: () => {},
    });
  };

  // 上传前校验
  const beforeUpload = (file: File) => {
    let isType = true;
    let isSize = true;
    let isLimit = true;
    let { size, name } = file;
    var suffix = getSuffix(name);
    var maxSize = 1048576 * size; //最大5MB
    if (size > maxSize) {
      message.warning(`上传的文件不能超过${size}MB`);
      isSize = false;
    }
    if (type && type.length && type.indexOf(suffix) == -1 && type[0] != "*") {
      message.warning(`文件类型错误,请上传${type.join(" ")}`);
      isType = false;
    }
    if (multiple && imageUrls.length >= limit) {
      message.warning(`最多上传${limit}份!`);
      isLimit = false;
    }
    return isType && isSize && isLimit;
  };

  let getSuffix = (str: string) => {
    var d = str.split(".");
    return "." + d[d.length - 1];
  };

  const handleDelMult = (item: string) => {
    setImageUrls(imageUrls.filter((curr) => curr != item));
    onChange && onChange({ res: item, type: "del" });
  };

  const inputRef = React.useRef<HTMLInputElement>(null)

  const handlerDel = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
    setImageUrl("");
    onChange && onChange("");
    setValue && setValue("");
  };
  const uploadButton = (
    <div
      className={`${uploadLess.uploadBox} text-center flex items-center justify-center `}
    >
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>上传图片</div>
      </div>
    </div>
  );

  useEffect(() => {
    // if (inputRef.current) {
    //   inputRef.current.value = ''
    // }
    if (value && !multiple) {
      console.log('value3333333333333333333',value);
      onChange && onChange(value);
      setValue && setValue(value);
      setImageUrl(value);
    }
  }, [value]);
  return (
    <div>
      <div className="relative">
        {button ? (
          <Button icon={<UploadOutlined />}>{children || "上传文件"}</Button>
        ) : (
          <div>
            {imageUrl ? (
              <img src={imageUrl} className={uploadLess.imgbox} alt="" />
            ) : (
              uploadButton
            )}
          </div>
        )}
        <input
          className={uploadLess.file_input}
          type="file"
          ref={inputRef}
          accept={type.toString()}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            changeFile(e);
          }}
        />
      </div>

      {imageUrl && !multiple && (
        <p className={`${uploadLess.link} pt-[6]`}>
          <a href={imageUrl} className="aui-ellipsis-1" target="_blank">
            <i className="icon t-[13]">&#xeabe;</i> {fileName || imageUrl}
          </a>
          <i
            className="icon t-[10]"
            onClick={handlerDel}
            style={{ verticalAlign: "super" }}
          >
            &#xe641;
          </i>
        </p>
      )}
      {multiple &&
        imageUrls.map((item, index) => {
          return (
            <p className={`${uploadLess.link} pt-[6]`}>
              <a href={item} className="aui-ellipsis-1" target="_blank">
                <i className="icon t-[13]">&#xeabe;</i>{" "}
                {fileName ? `${fileName}${index + 1}` : item}
              </a>
              <i
                className="icon t-[10]"
                onClick={() => handleDelMult(item)}
                style={{ verticalAlign: "super" }}
              >
                &#xe641;
              </i>
            </p>
          );
        })}
    </div>
  );
};
