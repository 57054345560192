// import Dgupload from "../../components/dg-upload";
// import { Button, Form, Input, Modal, Radio, Table, message } from "antd";
import proceedingDetailLess from "./css/detail.less";
import { memo, useEffect, useState } from "react";
import Dglist from "../../components/dg-list";
import Dglistitem from "../../components/dg-list-item";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import ajax from "../../assets/js/ajax";
import { downloadFile } from "../../assets/js/util";
import { Button } from "antd";
// import { ColumnsType } from "antd/es/table";

let navigate: NavigateFunction | null = null;

export default memo(() => {
  navigate = useNavigate();
  const params = useLocation().state as {
    id: string;
  };

  const [data, setData] = useState<any>({});
  const [complaint, setComplaint] = useState<any>({});

  const getDetail = () => {
    ajax.post({
      url: "/openPlatform/serviceComplaint/detail",
      data: params,
      self: this,
      success: (res) => {
        setComplaint(res || {});
        if (res.entity) {
          setData(res.entity);
        }
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <div>
      <div className="pb-[12]">
        <span className="t-[16] aui-192530 pr-[4]">
          投诉详情
        </span>
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>基础信息</p>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="项目名称">{data.projectName}</Dglistitem>
          <Dglistitem label="施工企业名称">
            {data.constructionUnitName}
          </Dglistitem>
          <Dglistitem label="服务名称">{data.name}</Dglistitem>
          <Dglistitem label="服务开始、结束时间">
            {`${data.startTime}~${data.insureEffectTimeEnd}`}
          </Dglistitem>
          <Dglistitem label="服务方案">
            <div>
              {data.plan ? (
                <span>
                  <a href={data.plan} target="_blank">
                    查看
                  </a>

                  <span
                    className="aui-link pl-[6]"
                    onClick={() => downloadFile(data.plan, "服务方案")}
                  >
                    下载
                  </span>
                </span>
              ) : (
                "-"
              )}
            </div>
          </Dglistitem>
          <Dglistitem label="服务合同">
            <div>
              {data.contract ? (
                <span>
                  <a href={data.contract} target="_blank">
                    查看
                  </a>

                  <span
                    className="aui-link pl-[6]"
                    onClick={() => downloadFile(data.contract, "服务合同")}
                  >
                    下载
                  </span>
                </span>
              ) : (
                "-"
              )}
            </div>
          </Dglistitem>
          <Dglistitem label="安责险保函编号">
            {data.safeLiabilityTradeNo}
          </Dglistitem>
          <Dglistitem label="保函期限">
            {data.insureEffectTimeStart && data.insureEffectTimeEnd
              ? `${data.insureEffectTimeStart}~${data.insureEffectTimeEnd}`
              : ""}
          </Dglistitem>
        </Dglist>
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>投诉内容</p>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="投诉对象">
            {complaint.respondentBeauName || "-"}
          </Dglistitem>
          <Dglistitem label="企业名称">
            {data.constructionUnitName || "-"}
          </Dglistitem>
          <div className="w-[100%]">
            <Dglistitem column={1} labelWidth="200px" label="投诉内容">
              {complaint.complainantRemark || "-"}
            </Dglistitem>
          </div>
          <Dglistitem label="投诉材料">
            {complaint.complainantAttachment ? (
              <span
                className="aui-link"
                onClick={() =>
                  downloadFile(complaint.complainantAttachment, "投诉材料")
                }
              >
                下载
              </span>
            ) : (
              "-"
            )}
          </Dglistitem>
        </Dglist>
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>处理结果</p>
        <Dglist column={1} labelWidth="200px">
          <Dglistitem label="处理状态">{complaint.statusName}</Dglistitem>
          <Dglistitem label="处理详情">{complaint.remark}</Dglistitem>
        </Dglist>
      </div>
      <div className="text-center mt-[32]">
        <Button
          type="default"
          className="w-160"
          onClick={() => {
            navigate!("/admin/accident/complaintList");
          }}
        >
          返回
        </Button>
      </div>
    </div>
  );
});
