import Dglist from "../../components/dg-list";
import Dgupload from "../../components/dg-upload";
import Dglistitem from "../../components/dg-list-item";
import { Button, Row, Col, message } from "antd";
import payLess from "./css/pay.less";
import { useState, useEffect, memo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ajax from "../../assets/js/ajax";

export default memo(() => {
	const navigate = useNavigate();

	const params = useLocation().state as {
		tradeNo: string;
		id: string;
	};

	const [data, setData] = useState({
		amountStr: "",
		gmtCreate: "",
		tradeNo: "",
		statusDesc: "",
		receiptAccount: "",
		receiptAccountBank: "",
		payTypeDesc: "",
		receiptAccountName: "",
	});
	// 地址： http://jinfu.baohan.com:8080/doc.html#/default/%E5%BC%80%E6%94%BE%E5%B9%B3%E5%8F%B0%E5%89%8D%E5%8F%B0-%E5%85%85%E5%80%BC%E8%AE%A2%E5%8D%95/uploadCertUsingPOST
	// 接口： /openPlatform/order/uploadCert
	// interface orderUploadcert {
	// 	tradeNo?: string; // 订单号
	// 	cert?: string; // 凭证
	// }
	//
	let [cert, setCert] = useState<string>("");
	// let [formData, setFormData] = useState<orderUploadcert>({
	// 	tradeNo: params.tradeNo, // 订单号
	// 	cert: cert, // 凭证
	// });
	// 不能将类型“unknown”分配给类型“string”。
	/**
	 * @author 伟健 / 张
	 * @name 订单详情
	 * @param
	 * @return
	 */
	let getPayInfo = () => {
		ajax.post({
			url: "/openPlatform/order/detail",
			data: params,
			self: this,
			success: (res) => {
				setCert(res.certificate);
				setData(res || {});
			},
			fail: () => {},
		});
	};
	let handlerCopy = () => {
		let bankAccount = `收款账户名称：${data.receiptAccountName} \n`;
		let bankDot = `收款账户开户行：${data.receiptAccountBank} \n`;
		let bankCardNo = `收款账号：${data.receiptAccount} \n`;
		let amount = `转账金额（元）：${data.amountStr} \n`;
		// let remark = `备注：${this.payInfo.payRemark} \n`;
		let content = bankAccount.concat(bankDot, bankCardNo, amount);
		let ipt = document.getElementById("copyTxt") as HTMLInputElement;
		ipt.value = content;
		ipt.select();
		document.execCommand("copy");
		message.success("复制成功");
	};

	/**
	 * @author 伟健 / 张
	 * @name 提交凭证
	 * @param
	 * @return
	 */
	let handlerSubmit = () => {
		console.log(cert);
		if (!cert) {
			message.warning("请上传凭证");
			return;
		}
		ajax.post({
			url: "/openPlatform/order/uploadCert",
			data: {
				tradeNo: params.tradeNo,
				cert,
			},
			self: this,
			success: (res) => {
				if (res) {
					message.success("上传成功");
					setTimeout(() => {
						navigate(-1);
					}, 1500);
				}
			},
			fail: () => {},
		});
	};

	useEffect(() => {
		getPayInfo();
	}, []);

	return (
		<div className="">
			<div className="pb-[12] flex justify-between">
				<div>
					<span className="t-[16] aui-192530 pr-[4]">充值</span>
				</div>
			</div>
			<div className={`aui-bg-white ${payLess.wrap}`}>
				<div className={`pl-[26] pr-[26] pb-[20] pt-[20] ${payLess.bor_bttom}`}>
					{/* <div>
						<span className={`t-[18] aui-192530 ${indexLess.title}`}>状态</span>
					</div> */}
					<Dglist column={2} labelWidth="140px">
						<Dglistitem label="订单号">{data.tradeNo}</Dglistitem>
						<Dglistitem label="提交时间">{data.gmtCreate}</Dglistitem>
						<Dglistitem label="充值方式">{data.payTypeDesc}</Dglistitem>
						<Dglistitem label="充值金额（元）">
							<span className="aui-F33F41">{data.amountStr}</span>
						</Dglistitem>
					</Dglist>
				</div>
				<div className="ui-padded-b-20">
					<div className={`${payLess.payInfo}`}>
						<div className="text-center t-[18] pt-[28]  pb-[28]">收款账户</div>
						<div className="pb-[40]">
							<Row gutter={[0, 16]}>
								<Col offset={7} span={5} className="">
									<span className="aui-788593">收款账户名称</span>
								</Col>
								<Col offset={2} span={7} className="">
									<span className="aui-#192530">{data.receiptAccountName}</span>
								</Col>
								<Col offset={7} span={5} className="">
									<span className="aui-788593">收款账户开户行</span>
								</Col>
								<Col offset={2} span={10}>
									<span className="aui-#192530">{data.receiptAccountBank}</span>
								</Col>
								<Col offset={7} span={5} className="">
									<span className="aui-788593">收款账号</span>
								</Col>
								<Col offset={2} span={10}>
									<span className="aui-#192530">{data.receiptAccount}</span>
								</Col>
								<Col offset={7} span={5} className="">
									<span className="aui-788593">转账金额(元)</span>
								</Col>
								<Col offset={2} span={10}>
									<span className="aui-#192530">{data.amountStr}</span>
								</Col>
							</Row>
							<div className="pt-[40] text-center">
								<span className="aui-link" onClick={handlerCopy}>
									一键复制
								</span>
							</div>
						</div>
					</div>
					<div className="aui-row">
						<div className={`${payLess.w_120} float-left text-right`}>
							<span className="aui-788593">上传凭证：</span>
						</div>
						<div className="float-left">
							<Dgupload setValue={setCert} value={cert}></Dgupload>
							<p className="pt-[4] aui-999CA1 t-[12]">请上传.png.jpg.jpeg,文件大小不超过10M</p>
						</div>
					</div>
					<div className="text-center pt-[48] pb-[48] ">
						<Button className="w-180" type="primary" onClick={handlerSubmit}>
							提交
						</Button>
					</div>
				</div>
			</div>
			{/* position: absolute; top: 0; left: 0; opacity: 0; z-index: -10 */}
			<textarea id="copyTxt" style={{ position: "absolute", top: 0, left: 0, opacity: 0, zIndex: -10 }}></textarea>
		</div>
	);
});
