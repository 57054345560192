import React, { useState, useEffect } from "react";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ajax from "../assets/js/ajax";
import IndexLess from "./index.less";

import LogoImg from "../assets/img/logo_txt_.png";
import item1 from "../assets/img/index/item1.png";
import item2 from "../assets/img/index/item2.png";
import item3 from "../assets/img/index/item3.png";
import item4 from "../assets/img/index/item4.png";
import item5 from "../assets/img/index/item5.png";
import title1 from "../assets/img/index/title1.png";
import title2 from "../assets/img/index/title2.png";
import title3 from "../assets/img/index/title3.png";
import title4 from "../assets/img/index/title4.png";
import title5 from "../assets/img/index/title5.png";
import title6 from "../assets/img/index/title6.png";
import title7 from "../assets/img/index/title7.png";
import title8 from "../assets/img/index/title8.png";
import titleLogo1 from "../assets/img/index/title-logo1.png";
import titleLogo2 from "../assets/img/index/title-logo2.png";
import titleLogo3 from "../assets/img/index/title-logo3.png";
import titleLogo4 from "../assets/img/index/title-logo4.png";
import titleLogo5 from "../assets/img/index/title-logo5.png";
import titleLogo6 from "../assets/img/index/title-logo6.png";
import content4Bg from "../assets/img/index/content4Bg.png";
import content5 from "../assets/img/index/content5.png";
import content5Item1 from "../assets/img/index/content5_item1.jpg";
import content5Item2 from "../assets/img/index/content5_item2.jpg";
import footer from "../assets/img/index/footer.png";
import kefu from "../assets/img/index/kefu.png";
import phone from "../assets/img/index/phone.png";
import gzh from "../assets/img/index/gzh.jpg";
import wx from "../assets/img/index/wx.png";
import icon from "../assets/img/index/icon.png";

export default () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [info, setInfo] = useState({
    realName: "",
  });

  const domain = process.env.REACT_APP_DOMAIN_URL;

  const style = {
    collapsed: {
      display: "none",
    },
    expanded: {
      display: "block",
    },
    activeColor: {
      background: "linear-gradient(270deg, #93AEF5 0%, #4E76D8 100%), #FFFFFF",
      color: "#FFFFFF",
    },
    normalColor: {
      background: "#FFFFFF",
      color: "#303133",
    },
  };

  const getCurrentInfo = () => {
    ajax.post({
      url: "/openPlatform/user/current?t=index",
      data: {},
      self: this,
      success: (res) => {
        localStorage.setItem("current", JSON.stringify(res));
        setInfo(res.user || {});
        // navigate && navigate("/home");
      },
      fail: () => {
        // setIslogin(true);
      },
    });
  };

  useEffect(() => {
    getCurrentInfo();
  }, []);

  return (
    <div className={IndexLess.wrapper}>
      <div className={IndexLess.bannerBox}>
        <div className={IndexLess.banner}>
          <div className={IndexLess.logo}>
            <img src={LogoImg} alt="" />
          </div>
          <ul className={IndexLess.menu}>
            <li className={IndexLess.menuItem}>
              <a href={domain}>首页</a>
            </li>
            <li className={IndexLess.menuItem}>
              <a href={domain + "guarantee"}>电子保函</a>
            </li>
            <li className={IndexLess.menuItem}>
              <a href={domain + "project"}>项目动态</a>
            </li>
            <li className={IndexLess.menuItem}>
              <a href={domain + "news"}>政策资讯</a>
            </li>
            <li className={IndexLess.menuItem}>
              <a href={domain + "check"}>保函验真</a>
            </li>
            <li className={IndexLess.menuItem}>
              <a href={domain + "institution"}>金融机构</a>
            </li>
            <li className={IndexLess.menuItem}>
              <a href={domain + "contact"}>关于我们</a>
            </li>
            <li className={IndexLess.menuItem}>
              <a href="/help">帮助支持</a>
            </li>
            <li className={IndexLess.menuItem}>
              {info.realName ? (
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    navigate && navigate("/home");
                  }}
                >
                  <i className="icon">&#xe63f;</i>
                  {info.realName}
                </span>
              ) : (
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    navigate && navigate("/login");
                  }}
                >
                  <i className="icon">&#xe63f;</i>
                  立即登录
                </span>
              )}
            </li>
          </ul>
        </div>
      </div>
      <div className={IndexLess.banner1}>
        <div className={IndexLess.banner1Box}>
          <p className={IndexLess.banner1Title}>
            金融科技新航道 卓越价值齐缔造
          </p>
          <p className={IndexLess.banner1Title2}>
            始终保持着开放的态度，发挥自身多年的行业能力积累，为生态和行业伙伴赋能
            <br />
            智领行业、数据精准、安全合规、稳定可靠
          </p>
        </div>
      </div>
      <div className={IndexLess.content1}>
        <div className={IndexLess.contentBox}>
          <div className={IndexLess.itemBox}>
            <div className={IndexLess.item}>
              <img src={item1} alt="" />
              <p className={IndexLess.itemTitle}>创新解决方案</p>
              <p className={IndexLess.itemTitle2}>
                面对不同保函场景的创新解决方案
              </p>
            </div>
            <div className={IndexLess.item}>
              <img src={item2} alt="" />
              <p className={IndexLess.itemTitle}>对接造就新时机</p>
              <p className={IndexLess.itemTitle2}>
                助力金融机构与用户之间的高效触达
              </p>
            </div>
            <div className={IndexLess.item}>
              <img src={item3} alt="" />
              <p className={IndexLess.itemTitle}>技术拓展支撑</p>
              <p className={IndexLess.itemTitle2}>
                提供标准的接口规范扩展，助力金融机构夯实技术底座
              </p>
            </div>
            <div className={IndexLess.item}>
              <img src={item4} alt="" />
              <p className={IndexLess.itemTitle}>高效出具保函</p>
              <p className={IndexLess.itemTitle2}>
                利用现代信息技术，实现保函的快速、安全、可追溯开具
              </p>
            </div>
            <div className={IndexLess.item}>
              <img src={item5} alt="" />
              <p className={IndexLess.itemTitle}>共建智慧金融</p>
              <p className={IndexLess.itemTitle2}>
                为金融机构提供协助，在应用层面实现拎包入驻
              </p>
            </div>
          </div>
          <p className={IndexLess.title}>一站式API数据服务</p>
        </div>
      </div>
      <div className={IndexLess.content2}>
        <div className={IndexLess.contentBox}>
          <div className={IndexLess.content2Wrap}>
            <div className={IndexLess.wrapItem}>
              <div className={IndexLess.titleBox}>
                <img src={title1} alt="" />
                <span className={IndexLess.title}>统计分析接口</span>
              </div>
              <div className={IndexLess.titleContent}>
                获取各类担保业务的数据统计和分析报告，为金融机构的决策提供数据支持。
              </div>
            </div>
            <div className={IndexLess.wrapItem}>
              <div className={IndexLess.titleBox}>
                <img src={title2} alt="" />
                <span className={IndexLess.title}>OFD文件生成</span>
              </div>
              <div className={IndexLess.titleContent}>
                存储电子保函文本及相关附件，支持不同文件在线自动转化成OFD，会进行全报文加密、数据完整性保护。
              </div>
            </div>
            <div className={IndexLess.wrapItem}>
              <div className={IndexLess.titleBox}>
                <img src={title3} alt="" />
                <span className={IndexLess.title}>客户身份验证接口</span>
              </div>
              <div className={IndexLess.titleContent}>
                通过与公安系统或工商系统的接口连接，以确保交易的合规性和安全性。实时验证客户身份的真实性。
              </div>
            </div>
            <div className={IndexLess.wrapItem}>
              <div className={IndexLess.titleBox}>
                <img src={title4} alt="" />
                <span className={IndexLess.title}>保前筛查、保后预警</span>
              </div>
              <div className={IndexLess.titleContent}>
                通过数据集成、数据处理、AI等技术构建风险决策引擎来帮助金融机构识别高风险客户和优质客户提升电子保函的风险管理能力。
              </div>
            </div>
            <div className={IndexLess.wrapItem}>
              <div className={IndexLess.titleBox}>
                <img src={title5} alt="" />
                <span className={IndexLess.title}>企业信息查询</span>
              </div>
              <div className={IndexLess.titleContent}>
                与金融监管部门的接口连接，获取申请人的信用报告和风险评分，为担保审核提供依据。
              </div>
            </div>
            <div className={IndexLess.wrapItem}>
              <div className={IndexLess.titleBox}>
                <img src={title6} alt="" />
                <span className={IndexLess.title}>电子签章</span>
              </div>
              <div className={IndexLess.titleContent}>
                客户电子合同签约接口，针对风险级别较高的API设计，具备加密、完整性、抗抵赖能力。
              </div>
            </div>
            <div className={IndexLess.wrapItem}>
              <div className={IndexLess.titleBox}>
                <img src={title7} alt="" />
                <span className={IndexLess.title}>短信平台</span>
              </div>
              <div className={IndexLess.titleContent}>
                提供短信发送服务，支持通过短信向用户发送动态口令，并校验动态口令合法性。
              </div>
            </div>
            <div className={IndexLess.wrapItem}>
              <div className={IndexLess.titleBox}>
                <img src={title8} alt="" />
                <span className={IndexLess.title}>基本户支付自动校验出函</span>
              </div>
              <div className={IndexLess.titleContent}>
                自主自研的基本户支付智能识别系统能对支付的银行账户是否属于企业基本户进行识别校验，打击个人或企业围标串标违法违规投标行为。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={IndexLess.content3}>
        <div className={IndexLess.contentBox}>
          <p className={IndexLess.content3Title}>
            产品核心特性
            <br />
            一栈式数据服务平台
          </p>
          <div className={IndexLess.content3Box}>
            <div className={IndexLess.boxItem}>
              <img src={titleLogo1} alt="" />
              <p className={IndexLess.boxTitle}>融合的数据平台</p>
            </div>
            <div className={IndexLess.boxItem}>
              <img src={titleLogo2} alt="" />
              <p className={IndexLess.boxTitle}>敏捷的业务创新</p>
            </div>
            <div className={IndexLess.boxItem}>
              <img src={titleLogo3} alt="" />
              <p
                className={IndexLess.boxTitle}
                style={{ letterSpacing: "-0.5px" }}
              >
                标准化技术接口
                <br />
                丰富的API、SDK接口
              </p>
            </div>
            <div className={IndexLess.boxItem}>
              <img src={titleLogo4} alt="" />
              <p className={IndexLess.boxTitle}>
                自主研发
                <br />
                代码级可控
              </p>
            </div>
            <div className={IndexLess.boxItem}>
              <img src={titleLogo5} alt="" />
              <p className={IndexLess.boxTitle}>
                金融级可用性
                <br />
                可靠性与可维护性
              </p>
            </div>
            <div className={IndexLess.boxItem}>
              <img src={titleLogo6} alt="" />
              <p className={IndexLess.boxTitle}>完善的安全保护能力</p>
            </div>
          </div>
        </div>
      </div>
      <div className={IndexLess.content4}>
        <div className={IndexLess.contentBox}>
          <div className={IndexLess.wrapBox}>
            <p className={IndexLess.title}>为什么要成为我们的合作伙伴？</p>
            <p className={IndexLess.title2}>
              创新并扩大您的客户覆盖范围，以提高客户价值和盈利能力。
            </p>
            <div className={IndexLess.box1}>
              <img src={content4Bg} alt="" />
            </div>
            <div className={IndexLess.box2}>
              <div
                className={IndexLess.collTitle}
                style={active == 0 ? style.activeColor : style.normalColor}
                onClick={() => setActive(0)}
              >
                <p>创新&强大的技术支持</p>
                {active == 0 ? <MinusOutlined /> : <PlusOutlined />}
              </div>
              <div
                className={IndexLess.collContent}
                style={active == 0 ? style.expanded : style.collapsed}
              >
                深知技术是推动金融创新的核心力量，因此，我们为合作伙伴提供强大的技术支持。
                “我们的平台拥有先进的 API
                接口和完善的开发工具，让你能够轻松接入各种金融服务。同时，我们的技术团队时刻关注行业动态，不断更新和优化平台功能，确保你始终站在金融科技的前沿。”
              </div>
              <div
                className={IndexLess.collTitle}
                style={active == 1 ? style.activeColor : style.normalColor}
                onClick={() => setActive(1)}
              >
                <p>为客户创造价值&丰富的资源整合</p>
                {active == 1 ? <MinusOutlined /> : <PlusOutlined />}
              </div>
              <div
                className={IndexLess.collContent}
                style={active == 1 ? style.expanded : style.collapsed}
              >
                在我们的金融技术服务开放平台上，你将获得丰富的资源整合优势。
                “我们与众多金融机构、科技企业和行业专家建立了紧密的合作关系，为你提供全方位的资源支持。无论是数据还是市场渠道，都能在这里找到解决方案，帮助你快速拓展业务，实现资源的最大化利用。”
              </div>
              <div
                className={IndexLess.collTitle}
                style={active == 2 ? style.activeColor : style.normalColor}
                onClick={() => setActive(2)}
              >
                <p>推动盈利增长&广阔的发展机遇</p>
                {active == 2 ? <MinusOutlined /> : <PlusOutlined />}
              </div>
              <div
                className={IndexLess.collContent}
                style={active == 2 ? style.expanded : style.collapsed}
              >
                在这个快速发展的数字时代，金融行业正经历着深刻的变革，而我们的平台处于这场变革的前沿。
                “我们的平台汇聚了行业内最先进的金融技术和创新理念，为你提供了广阔的发展空间。你能在这里找到适合自己的机会，实现业务的快速增长和转型。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={IndexLess.content5}>
        <div className={IndexLess.contentBox}>
          <p className={IndexLess.title}>数据服务对接流程</p>
          <img className={IndexLess.content5Img} src={content5} alt="" />
          <div className={IndexLess.contentItem}>
            <img src={content5Item1} alt="" />
            <img src={content5Item2} alt="" />
          </div>
        </div>
      </div>
      <div className={IndexLess.footer}>
        <div className={IndexLess.contentBox}>
          <div className={IndexLess.footerBox}>
            <div className={IndexLess.footerLeft}>
              <div className={IndexLess.title}>
                <img src={icon} alt="" />
                <div className={IndexLess.titleBox}>
                  <p className={IndexLess.t1}>
                    盐城市数字一体化电子保函综合服务平台
                  </p>
                  <p className={IndexLess.t2}>
                    我们致力于为您提供售前购买咨询服务，以及全面的技术售后服务，添加客服微信进行服务咨询。
                  </p>
                </div>
              </div>
              <div className={IndexLess.footerBg}>
                <img src={footer} alt="" />
              </div>
            </div>
            <div className={IndexLess.der}></div>
            <div className={IndexLess.footerRight}>
              <div className={IndexLess.rlBox}>
                <div className={IndexLess.zhichi}>支持</div>
                <div className={IndexLess.kefu}>
                  <a href="/help">
                    <img src={kefu} alt="" />
                    <span>帮助支持</span>
                  </a>
                </div>
                <div className={IndexLess.phone}>
                  <img src={phone} alt="" />
                  <span>400-816-1767</span>
                </div>
              </div>
              <div className={IndexLess.rrBox}>
                <p className={IndexLess.t1}>关注或联系我们</p>
                <p className={IndexLess.t2}>添加公众号，即可关注云资讯</p>
                <div className={IndexLess.codes}>
                  <div className={IndexLess.code}>
                    <img src={gzh} alt="" />
                    <p>微信公众号</p>
                  </div>
                  <div className={IndexLess.code}>
                    <img src={wx} alt="" />
                    <p>客服微信</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-[8] pb-[22]">
            <p className="t-[12] t-[#909399] leading-[14] mb-[8]">
              声明：本平台仅提供技术支持，所有保函产品均由保函下发金融机构提供，销售、理赔等服务均由本平台合作的金融机构提供
              版权所有：盐城函通科技有限公司&nbsp;&nbsp;苏ICP备2024119490号 
            </p>
            <p className="t-[12] t-[#B1B1B1] leading-[14] mb-[8]">
              指导单位：盐城市发展和改革委员会、盐城市财政局、盐城市国资委、盐城市数据局、盐城市市场监督管理局、人民银行盐城市分行、盐城市金融监管分局
              主办单位：江苏黄海金融控股集团有限公司
            </p>
            <p className="t-[12] t-[#B1B1B1] leading-[14] ">
              运营单位：盐城函通科技有限公司
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
