import { message } from "antd";
// import { isJsonStr } from "./util.tsx";

export default class WSocket {
  ws = null;
  instance = null;

  reconnectTimer = null; // 重连计时器
  reconnectCount = 10; // 变量保存，防止丢失

  heartTimeOut = null; // 心跳计时器
  ServerHeartTimeOut = null; // 心跳计时器
  time = 1 * 1000; //心跳间隔时间
  timeout = 3 * 1000; // 心跳超时间隔
  webSocketState = false; //连接状态

  options = {
    url: null, // 链接的通道的地址
    heartMsg: "ping",
    isReconnect: true, // 是否自动重连
    isDestory: false, // 是否销毁
    reconnectTime: 3000, // 重连时间间隔
    reconnectCount: -1, // 重连次数 -1 则不限制
    openCb: null, // 连接成功的回调
    closeCb: null, // 关闭的回调
    messageCb: null, // 消息的回调
    errorCb: null, // 错误的回调
  };

  constructor(ops) {
    this.webSocketState = false;
    Object.assign(this.options, ops);
    this.create();
  }

  static getInstance(ops) {
    if (!this.instance) {
      this.instance = new WSocket(ops);
    }
    return this.instance;
  }

  /**
   * 建立连接
   */
  create() {
    if (!("WebSocket" in window)) {
      message.error("当前浏览器不支持，无法使用");
      return;
    }
    if (!this.options.url) {
      message.error("地址不存在，无法建立通道");
      return;
    }
    this.ws = null;
    this.webSocketState = false;
    this.ws = new WebSocket(this.options.url);
    this.onopen(this.options.openCb);
    this.onclose(this.options.closeCb);
    this.onmessage(this.options.messageCb);
  }

  /**
   * 自定义连接成功事件
   * 如果callback存在，调用callback，不存在调用OPTIONS中的回调
   * @param {Function} callback 回调函数
   */
  onopen(callback) {
    this.ws.onopen = (event) => {
      clearTimeout(this.reconnectTimer); // 清除重连定时器
      this.options.reconnectCount = this.reconnectCount; // 计数器重置
      // 建立心跳机制
      this.webSocketState = true;
      this.reset();
      this.start();
      if (typeof callback == "function") {
        callback(event);
      } else {
        typeof this.options.openCb == "function" && this.options.openCb(event);
      }
    };
  }

  /**
   * 自定义关闭事件
   * 如果callback存在，调用callback，不存在调用OPTIONS中的回调
   * @param {Function} callback 回调函数
   */
  onclose(callback) {
    this.ws.onclose = (event) => {
      this.webSocketState = false;
      this.reset();
      !this.options.isDestory && this.onreconnect();
      if (typeof callback == "function") {
        callback(event);
      } else {
        typeof this.options.closeCb == "function" &&
          this.options.closeCb(event);
      }
    };
  }

  /**
   * 自定义错误事件
   * 如果callback存在，调用callback，不存在调用OPTIONS中的回调
   * @param {Function} callback 回调函数
   */
  onerror(callback) {
    this.ws.onerror = (event) => {
      this.webSocketState = false;
      this.ws.onclose();
      if (typeof callback == "function") {
        callback(event);
      } else {
        typeof this.options.errorCb == "function" &&
          this.options.errorCb(event);
      }
    };
  }

  /**
   * 自定义消息监听事件
   * 如果callback存在，调用callback，不存在调用OPTIONS中的回调
   * @param {Function} callback 回调函数
   */
  onmessage(callback) {
    this.ws.onmessage = (event) => {
      const { data } = event;
      if (data == "pong") {
        this.webSocketState = true;
      }
      // const res = isJsonStr(data) ? JSON.parse(data) : data;
      // this.reset();
      // this.start(this.send(this.options.heartMsg), this.ws.onclose());
      if (typeof callback == "function") {
        callback(data);
      } else {
        typeof this.options.messageCb == "function" &&
          this.options.messageCb(data);
      }
    };
  }

  /**
   * 自定义发送消息事件
   * @param {String} data 发送的文本
   */
  send(data) {
    if (this.ws.readyState != this.ws.OPEN) {
      message.error("没有连接到服务器，无法推送");
      return;
    }
    // data = JSON.stringify(data);
    this.ws.send(data);
  }

  /**
   * 连接事件
   */
  onreconnect() {
    if (this.isReconnect) {
      return;
    }

    this.isReconnect = true;
    this.webSocketState = false;
    this.reconnectTimer && clearTimeout(this.reconnectTimer);

    this.reconnectTimer = setTimeout(() => {
      this.create();
      this.isReconnect = false;
    }, this.options.reconnectTime);
  }

  // // 重置心跳检测定时器
  reset() {
    this.heartTimeOut && clearTimeout(this.heartTimeOut);
    this.ServerHeartTimeOut && clearTimeout(this.ServerHeartTimeOut);
  }

  /**
   * 启动心跳
   * @param {Function} cb 回调函数
   */
  start() {
    this.heartTimeOut = setTimeout((e) => {
      this.send(this.options.heartMsg);
      this.wait();
    }, this.time);
  }

  wait() {
    this.webSocketState = false;
    this.ServerHeartTimeOut = setTimeout((e) => {
      if (this.webSocketState) {
        this.start();
        return;
      }
      // 规定时间内未收到消息开始重连
      // reconnect && reconnect(e);
      this.onreconnect();
    }, this.timeout);
  }

  /**
   * 销毁
   */
  destroy() {
    this.reset();
    clearTimeout(this.reconnectTimer); // 清除重连定时器
    this.options.isDestory = true;
    this.webSocketState = false;
    this.ws.close();
    this.ws = null;
    this.instance = null;
  }
}
