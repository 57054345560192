import Dglist from "../../components/dg-list";
import Dglink from "../../components/dg-link";
import Dglistitem from "../../components/dg-list-item";

import { Button, Form, Input, Modal, Select, message } from "antd";
import indexLess from "./css/detail.less";
import React, { useState, useEffect, memo } from "react";
import { useLocation } from "react-router-dom";
import ajax from "../../assets/js/ajax";
const { Option } = Select;
const { TextArea } = Input;
export default memo(() => {
  // const navigate = useNavigate();
  const params = useLocation().state as {
    id?: string;
    tradeNo?: string;
  };
  let [refForm] = Form.useForm();
  let [invoiceVisible, setInvoiceVisible] = useState(false);
  let [allInvoiceType, setAllInvoiceType] = useState<any[]>([]);
  const [data, setData] = useState({
    amountStr: "",
    gmtCreate: "",
    invoiceHead: "",
    invoiceTypeDesc: "",
    linkPhone: "",
    status: null,
    statusDesc: "",
    tradeNo: "",
    unifyCreditCode: "",
    addr: "",
    bankCardNo: "",
    bankDot: "",
    eleInvoiceUrl: "",
    email: "",
    remark: "",
    tel: "",
    version: "",
  });

  /**
   * @author 伟健 / 张
   * @name 发票详情
   * @param
   * @return
   */
  let getDetail = () => {
    let url = "/openPlatform/invoice/detail";
    if (params.tradeNo) {
      url = "/openPlatform/order/invoiceDetail";
    }
    ajax.post({
      url: url,
      data: params,
      self: this,
      success: (res) => {
        setData(res || {});
        refForm.setFieldsValue(res || {});
      },
      fail: () => {},
    });
    // 	/openPlatform/order/invoiceDetail
  };

  /**
   * @author 伟健 / 张
   * @name 提交发票申请
   * @param
   * @return
   */
  let handlerSubmint = (values: any) => {
    ajax.post({
      url: "/openPlatform/invoice/apply",
      data: values,
      self: this,
      success: (res) => {
        if (res) {
          setInvoiceVisible(false);
          message.success("提交成功");
          getDetail();
        }
      },
      fail: () => {},
    });
  };

  /**
   * @author 伟健 / 张
   * @name 发票类型
   * @param
   * @return
   */
  let getInvoiceType = () => {
    ajax.post({
      url: "/openPlatform/common/allInvoiceType",
      data: {},
      self: this,
      success: (res) => {
        setAllInvoiceType((data: any) => {
          data = [];
          res.map((el: any) => {
            data.push({ ...el });
          });
          return data;
        });
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    getDetail();
    getInvoiceType();
  }, []);

  return (
    <div className="">
      <div className="pb-[12] flex justify-between">
        <div>
          <span className="t-[16] aui-192530 pr-[4]">
            发票详情
          </span>
        </div>
      </div>
      <div className={`aui-bg-white ${indexLess.wrap}`}>
        <div
          className={`pl-[26] pr-[26] pb-[20] pt-[20] relative ${indexLess.bor_bttom}`}
        >
          <div>
            <span className={`t-[18] aui-192530 ${indexLess.title}`}>
              发票状态
            </span>
          </div>
          <Dglist column={2} labelWidth="140px">
            <Dglistitem label="状态">{data.statusDesc || "--"}</Dglistitem>
            <Dglistitem label="发票附件">
              <Dglink href={data.eleInvoiceUrl}></Dglink>
            </Dglistitem>
            <Dglistitem label="发票类型">
              {data.invoiceTypeDesc || "--"}
            </Dglistitem>
            <Dglistitem label="备注">{data.remark || "--"}</Dglistitem>
            <Dglistitem label="提交时间">{data.gmtCreate || "--"}</Dglistitem>
          </Dglist>

          <div className={`${indexLess.oper_box}`}>
            {data.status == 2 && (
              <Button
                type="primary"
                onClick={() => {
                  setInvoiceVisible(true);
                }}
              >
                重新申请
              </Button>
            )}
          </div>
        </div>
        <div className=" pl-[26] pr-[26] pb-[20] pt-[20]">
          <div className="pb-[16]">
            <span className={`t-[18] aui-192530 ${indexLess.title}`}>
              开票信息
            </span>
          </div>
          <Dglist column={2} labelWidth="140px">
            <Dglistitem label="开票金额">
              <span className="aui-F33F41">{data.amountStr}</span>元
            </Dglistitem>
            <Dglistitem label=""></Dglistitem>
            <Dglistitem label="发票抬头">{data.invoiceHead || "--"}</Dglistitem>
            <Dglistitem label="税务登记号">
              {data.unifyCreditCode || "--"}
            </Dglistitem>
            <Dglistitem label="地址">{data.addr || "--"}</Dglistitem>
            <Dglistitem label="企业电话">{data.tel || "--"}</Dglistitem>
            <Dglistitem label="开户行">{data.bankDot || "--"}</Dglistitem>
            <Dglistitem label="银行账号">{data.bankCardNo || "--"}</Dglistitem>
            <Dglistitem label="联系电话">{data.linkPhone || "--"}</Dglistitem>
            <Dglistitem label="联系邮箱">{data.email || "--"}</Dglistitem>
          </Dglist>
        </div>
      </div>

      <Modal
        title="申请发票"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setInvoiceVisible(false)}
        open={invoiceVisible}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={refForm}
          onFinish={handlerSubmint}
        >
          <Form.Item
            label=""
            className="aui-hide"
            hidden={false}
            name="tradeNo"
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item label="" className="aui-hide" hidden={false} name="id">
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            label=""
            className="aui-hide"
            hidden={false}
            name="version"
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            label="发票抬头"
            name="invoiceHead"
            rules={[{ required: true, message: "请输入发票抬头" }]}
          >
            <Input placeholder="请输入发票抬头" />
          </Form.Item>
          <Form.Item
            label="发票类型"
            name="invoiceType"
            rules={[{ required: true, message: "请选择发票类型" }]}
          >
            <Select style={{ width: 180 }} placeholder="请选择发票类型">
              {allInvoiceType.map((el: any, index: number) => {
                return (
                  <Option key={index} value={el.key}>
                    {el.value}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="税务登记号"
            name="unifyCreditCode"
            rules={[
              { required: true, message: "请输入税务登记号(统一社会信用代码)" },
            ]}
          >
            <Input placeholder="请输入税务登记号(统一社会信用代码)" />
          </Form.Item>
          <Form.Item label="开户行" name="bankDot">
            <Input placeholder="请输入开户行" />
          </Form.Item>
          <Form.Item label="银行账户" name="bankCardNo">
            <Input placeholder="请输入银行账户" />
          </Form.Item>
          <Form.Item label="地址" name="addr">
            <TextArea rows={4} placeholder="请输入企业地址" />
          </Form.Item>
          <Form.Item
            label="企业电话"
            name="tel"
            rules={[
              {
                pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
                message: "请输入企业电话",
              },
            ]}
          >
            <Input placeholder="请输入企业电话" />
          </Form.Item>
          <Form.Item
            label="联系电话"
            name="linkPhone"
            rules={[
              { required: true, message: "请输入联系电话" },
              {
                pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
                message: "请输入联系电话",
              },
            ]}
          >
            <Input maxLength={11} placeholder="请输入联系电话" />
          </Form.Item>

          <Form.Item
            label="电子邮件"
            name="email"
            rules={[
              {
                pattern:
                  /^[a-z\d]+(\.[a-z\d]+)*@([\da-z](-[\da-z])?)+(\.{1,2}[a-z]+)+$/,
                message: "请输入电子邮件",
              },
            ]}
          >
            <Input placeholder="请输入电子邮件" />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button type="primary" className="w-150" htmlType="submit">
              提交申请
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});
