import { Form, Input } from "antd";
import indexLess from "./css/index.less";
import { Select, Button, Modal } from "antd";
import { useState, useEffect } from "react";
import ajax from "../../assets/js/ajax";

import EmptyPage from "../../components/emptyPage";
import { hasPermission } from "../../assets/js/hasPermission";

import { useNavigate } from "react-router-dom";
const { Option } = Select;
export default () => {
  const navigate = useNavigate();
  let [payType, setPayType] = useState<any[]>([]);
  let [balance, setBalance] = useState(0.0);
  /**
   * @author 伟健 / 张
   * @name 提交
   * @param
   * @return
   */
  let handlerSubmint = (values: any) => {
    ajax.post({
      url: "/openPlatform/order/recharge",
      data: values,
      self: this,
      success: (res) => {
        navigate("/admin/rechargePay", {
          state: { tradeNo: res.tradeNo, id: res.id },
        });
      },
      fail: () => {
        Modal.info({
          title: "请先完善企业信息",
          content: (
            <div>
              <p>当前您的企业信息未完善，需先进行完善企业信息后继续</p>
            </div>
          ),
          onOk() {
            navigate("/admin/companyEdit");
          },
        });
      },
    });
  };

  /**
   * @author 伟健 / 张
   * @name 支付方式
   * @param
   * @return
   */
  let getPayType = () => {
    ajax.post({
      url: "/openPlatform/common/allPayTypes",
      data: {},
      self: this,
      success: (res: any) => {
        setPayType(() => [...res]);
      },
      fail: () => {},
    });
  };

  /**
   * @author 伟健 / 张
   * @name 账号信息
   * @param
   * @return
   */
  let getAccountInfo = () => {
    ajax.post({
      url: "/openPlatform/user/current?t=company",
      data: {},
      self: this,
      success: (res) => {
        if (res && res.user) {
          setBalance(res.user.balance || 0.0);
        }
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    if (hasPermission("openPlatform:order:recharge")) {
    getPayType();
    getAccountInfo();
    }
  }, []);
  return (
    <div style={{ width: "100%", height: "100%" }}>
      {hasPermission("openPlatform:order:recharge") ? (
        <div className="">
          <div className="pb-[12]">
            <span className="t-[16] aui-192530 pr-[4]">
              充值
            </span>
          </div>
          <div className={`aui-bg-white ${indexLess.wrap}`}>
            <div
              className={`pl-[26] pr-[26] pb-[20] pt-[20] ${indexLess.bor_bttom}`}
            >
              <div>
                <span className="aui-192530">当前可用额度：</span>{" "}
                <span className="aui-FF4D4F t-[18] font-700">
                  ￥{balance}
                </span>{" "}
                元
              </div>
            </div>
            <div className=" pl-[26] pr-[26] pb-[20] pt-[20]">
              <Form
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 6 }}
                autoComplete="off"
                onFinish={handlerSubmint}
              >
                <Form.Item
                  label="充值金额："
                  name="amount"
                  rules={[
                    { required: true, message: "请输入充值金额" },
                    {
                      pattern: /^\d{0,9}(?:\.\d{0,2})?$/,
                      message: "请输入充值金额",
                    },
                  ]}
                >
                  <Input maxLength={12} placeholder="请输入充值金额" />
                </Form.Item>
                <Form.Item
                  label="充值方式："
                  name="payType"
                  rules={[{ required: true, message: "请选择充值方式" }]}
                >
                  <Select placeholder="请选择充值方式" style={{ width: 170 }}>
                    {payType.map((el, index) => {
                      return (
                        <Option key={index} value={el.key}>
                          {el.value}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="" wrapperCol={{ offset: 3 }}>
                  <Button type="primary" className="w-150" htmlType="submit">
                    下一步
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      ) : (
        <EmptyPage />
      )}
    </div>
  );
};
