export const hasPermission = (permission) => {
  const current = localStorage.getItem("current");
  if (current) {
    let scopes = JSON.parse(current)?.roles[0]?.scopes || "";
    let permissions = scopes.split(",") || [];
    return permissions.includes(permission);
  } else {
    return false;
  }
};
