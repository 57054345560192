import Header from "./components/header";
import DgCopyRight from "./components/dg-copyright";
import loginBg from "./assets/img/login_bg.png";
import indexLess from "./login.less";
import { encryptDES } from "./assets/js/util";
import { Button, Row, Col, Form, Input, Alert } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ajax from "./assets/js/ajax";

type AlertType = "success" | "info" | "warning" | "error" | undefined;

export default () => {
  let [imgCode, setImgCode] = useState("");
  const [form] = Form.useForm();
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState<AlertType>("error");

  const navigate = useNavigate();

  const handleClose = () => {
    setShowAlert(false);
  };

  /**
   * @author 伟健 / 张
   * @name 登录
   * @param
   * @return
   */
  let handlerLogin = (values: any) => {
    values.password = encryptDES(values.password);
    ajax.post({
      url: "/openPlatform/auth/login",
      data: values,
      self: this,
      success: (res) => {
        navigate("/home");
      },
      fail: (err) => {
        setShowAlert(true);
        setAlertType("error");
        setAlert(err.msg);
        getGetcaptcha();
      },
    });
  };

  /**
   * @author 伟健 / 张
   * @name 图形码
   * @param
   * @return
   */
  let getGetcaptcha = () => {
    ajax.post({
      url: "/openPlatform/common/getcaptcha",
      data: {},
      self: this,
      success: (res) => {
        if (res) {
          setImgCode(res);
        }
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    getGetcaptcha();
  }, []);

  return (
    <div className="w-[100%] min-w-[1440] h-[100vh] min-h-[750] relative bg-[#F7F7F9] flex flex-col justify-between">
      <Header></Header>
      <div className="w-[100%]">
        <div
          className={`${indexLess.loginContent} w-[1200] h-[516] m-[auto] relative flex justify-center items-center rounded-[4] overflow-hidden`}
        >
          <img width={732} height={516} src={loginBg} alt="" />
          <div className="w-[468] h-[516] bg-[#FFFFFF] px-[72]">
            <p className={`t-[24] t-[#192530] text-center pt-[60] ${!showAlert ? 'pb-[40]' : 'pb-[20]'} `}>
              登录
            </p>
            {showAlert && (
              <div className="pb-[20]">
                <Alert
                  showIcon
                  closable
                  message={alert}
                  type={alertType}
                  afterClose={handleClose}
                  icon={<i className="icon t-[16]">&#xe664;</i>}
                  className="!px-[16] !py-[8] !border-[0]"
                />
              </div>
            )}
            <Form
              form={form}
              size="large"
              autoComplete="off"
              onFinish={handlerLogin}
              initialValues={{ remember: true }}
            >
              <Form.Item
                label=""
                name="loginName"
                rules={[{ required: true, message: "请输入账号名" }]}
              >
                <Input placeholder="请输入账号名" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "请输入登录密码" }]}
                className="relative"
              >
                <Input.Password placeholder="请输入登录密码" />
              </Form.Item>
              <Form.Item
                name="catchcode"
                rules={[{ required: true, message: "请输入验证码" }]}
              >
                <Row gutter={8}>
                  <Col span={17}>
                    <Input placeholder="请输入验证码" />
                  </Col>
                  <Col span={7}>
                    <img
                      src={imgCode}
                      height={48}
                      className={indexLess.imgCode}
                      onClick={() => getGetcaptcha()}
                      alt=""
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className={indexLess.login_button}
                >
                  登录
                </Button>
              </Form.Item>
              <div className="flex justify-between items-center">
                <p>
                  <span
                    className="aui-link"
                    onClick={() => {
                      navigate("/forgotPasw");
                    }}
                  >
                    忘记密码？
                  </span>
                </p>
                <p>
                  <span
                    className="aui-link"
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    快速注册
                  </span>
                </p>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <DgCopyRight paddingTop="0px" paddingBottom="30px"></DgCopyRight>
    </div>
  );
};
