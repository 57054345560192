import Dgupload from "../../components/dg-upload";
import invoicesLess from "./css/invoices.less";
import Dgform from "../../components/dg-form";
import {
  Table,
  Button,
  Form,
  Pagination,
  Modal,
  Input,
  Select,
  message,
} from "antd";
import type { FormInstance } from "antd";
import { memo, useEffect, useRef, useState } from "react";
import { itmeType } from "../../types";
import ajax from "../../assets/js/ajax";
import type { ColumnsType } from "antd/es/table";
import { NavigateFunction, useNavigate } from "react-router-dom";
import EmptyPage from "../../components/emptyPage";
import { hasPermission } from "../../assets/js/hasPermission";

let navigate: NavigateFunction | null = null;

interface DataType {
  key: React.Key;
  title: string;
  dataIndex: string;
  align: string;
}

interface baoHan {
  pageNo: number | null; // 页数
  pageSize: number | null; // 条数
  gmtCreateBegin?: string; // 提交开始时间
  gmtCreateEnd?: string; // 提交结束时间
  sortDirection?: string; // 排序方向
  sortField?: string; // 排序字段
  status?: number | null; // 状态
  tradeNo?: string; // 订单编号
}

export default memo(() => {
  navigate = useNavigate();
  const columns: ColumnsType<DataType> = [
    {
      title: "提交时间",
      dataIndex: "gmtCreate",
      key: "gmtCreate",
      align: "center",
      // width: 200,
      // ellipsis: true,
    },
    {
      title: "订单号",
      key: "tradeNo",
      dataIndex: "tradeNo",
      align: "center",
      width: 260,
      // width: 200,
      // ellipsis: true,
    },
    {
      title: "发票类型",
      dataIndex: "invoiceTypeName",
      key: "invoiceTypeName",
      align: "center",
      // width: 200,
      // ellipsis: true,
    },
    {
      title: "发票抬头",
      dataIndex: "invoiceHead",
      key: "invoiceHead",
      align: "center",
      // width: 200,
      // ellipsis: true,
    },
    {
      title: "保函品种",
      dataIndex: "guaranteeCategoryName",
      key: "guaranteeCategoryName",
      align: "center",
      // width: 200,
      // ellipsis: true,
    },
    {
      title: "发票金额(元)",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      // width: 200,
      // ellipsis: true,
    },
    {
      title: "状态",
      dataIndex: "statusDesc",
      key: "statusDesc",
      align: "center",
      width: 80,
      fixed: "right",
    },
    {
      title: "操作",
      dataIndex: "",
      key: "oper",
      align: "center",
      width: 88,
      fixed: "right",
      render: (text, record: any) => {
        let oper = [];

        const check = (
          <span
            className="aui-link pl-[4]"
            onClick={() => {
              navigate!("/admin/invoices/detail", {
                state: { id: record.id },
              });
            }}
          >
            查看
          </span>
        );

        const invoice = (
          <span
            className="aui-link pl-[4]"
            onClick={() => {
              setId(record.id);
              setInvoicingVisible(true);
            }}
          >
            开票
          </span>
        );

        oper.push(check);
        if (record.status == 0 || record.status == 2) {
          oper.push(invoice);
        }

        return oper;
      },
    },
    //
  ];

  const [totalItems, setTotalItems] = useState<number>(0);
  const [statusAll, setStatusAll] = useState<any[]>([]); //状态选择框
  const [formItems, setFormItems] = useState<itmeType[]>([
    {
      label: "订单号",
      type: "input",
      key: "tradeNo",
      des: "请输入订单编号",
    },
    {
      label: "状态",
      type: "select",
      options: statusAll,
      key: "status",
      des: "请选择状态",
    },
    {
      label: "提交结束时间",
      type: "date",
      model: [],
      key: "gmtCreateBegin,gmtCreateEnd",
      des: "请选择提交结束时间",
    },
  ]);

  const [data, setData] = useState<any>([]);

  const [formData, setFormData] = useState<baoHan>({
    pageNo: 1, // 页数
    pageSize: 10, // 条数
    gmtCreateBegin: "", // 提交开始时间
    gmtCreateEnd: "", // 提交结束时间
    sortDirection: "", // 排序方向
    sortField: "", // 排序字段
    status: null, // 状态
    tradeNo: "", // 订单编号
  });

  const [invoiceForm] = Form.useForm();
  const [invoicingVisible, setInvoicingVisible] = useState<boolean>(false);
  const [eleInvoiceUrl, setEleInvoiceUrl] = useState<string>("");
  const [id, setId] = useState<number>(0);

  const formRef = useRef<{
    getFormRef: () => FormInstance<any>;
    getValues: () => any;
  }>();

  /**
   * @author: huangtaizhu@nongdaxia.com.cn
   * @description: 获取发票类型
   * @return {*}
   */
  const handleStatus = () => {
    ajax.post({
      url: "/openPlatform/common/allInvoiceState",
      data: {},
      self: this,
      success: (res) => {
        setStatusAll((data: any) => {
          res.map((el: any, index: Number) => {
            data.push({ ...el, key: index });
          });
          return data;
        });
        // setFormItems(JSON.parse(JSON.stringify(formItems)));
      },
      fail: () => {},
    });
  };

  const handleSubmitInvoice = (values: any) => {
    ajax.post({
      url: "/openPlatform/invoiceOrder/invoiceUpload",
      data: { id: id, ...values, eleInvoiceUrl },
      self: this,
      success: (res) => {
        if (res) {
          getList();
          setInvoicingVisible(false);
          message.success("操作成功");
        }
      },
      fail: () => {},
    });
  };

  const handelSeach = (values: any) => {
    setFormData({
      pageNo: 1,
      pageSize: 10,
      ...values,
    });
  };

  const handelPageChange = (pageNo: number, pageSize: number) => {
    setFormData({
      pageNo,
      pageSize,
      ...formRef.current?.getValues(),
    });
  };

  const getList = (data?: any) => {
    ajax.post({
      url: "/openPlatform/invoiceOrder/page",
      data: Object.assign(formData, data || {}),
      self: this,
      success: (res) => {
        setData(() => {
          return res.result.map((e: any, i: number) => {
            return { ...e, key: i };
          });
        });
        setTotalItems(res.totalItems);
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    handleStatus();
  }, []);

  useEffect(() => {
    if (hasPermission("openPlatform:invoiceOrder:page")) {
      getList();
    }
  }, [formData]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {hasPermission("openPlatform:invoiceOrder:page") ? (
        <div>
          <div className="pb-[12]">
            <span className="t-[16] aui-192530 pr-[4]">
              保函发票列表
            </span>
          </div>
          <div className={`aui-bg-white ${invoicesLess.wrap} `}>
            <div className={` ${invoicesLess.bor_bttom}  p-[26]`}>
              <Dgform ref={formRef} items={formItems} handelSeach={handelSeach}>
                <Form.Item className="ml-[auto] mr-[0]">
                  <Button type="primary" htmlType="submit">
                    搜索
                  </Button>
                </Form.Item>
              </Dgform>
            </div>
            <div className="p-[26]">
              <Table
                rowSelection={{ type: "checkbox" }}
                columns={columns}
                pagination={false}
                dataSource={data}
                scroll={{ x: 1300 }}
              />
              <div className="text-center pt-[24]">
                <Pagination
                  onChange={handelPageChange}
                  total={totalItems}
                  showTotal={(total) => `总条数 ${total}`}
                ></Pagination>
              </div>
            </div>
          </div>

          <Modal
            title="开票"
            forceRender={true}
            maskClosable={true}
            onCancel={() => setInvoicingVisible(false)}
            open={invoicingVisible}
            footer={false}
            centered
            width={700}
          >
            <Form
              preserve={false}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 12 }}
              form={invoiceForm}
              onFinish={handleSubmitInvoice}
            >
              <Form.Item
                label="开票状态"
                name="status"
                rules={[{ required: true, message: "请选择开票状态" }]}
              >
                <Select
                  style={{ width: "100%" }}
                  options={[
                    {
                      value: "1",
                      label: "已开票",
                    },
                    {
                      value: "2",
                      label: "开票失败",
                    },
                  ]}
                  placeholder="请选择开票状态"
                />
              </Form.Item>
              <Form.Item
                label="发票号"
                name="invoiceNo"
                rules={[{ required: true, message: "请输入发票号" }]}
              >
                <Input placeholder="请输入发票号" />
              </Form.Item>
              <Form.Item label="发票附件" name="eleInvoiceUrl">
                <div>
                  <div className={invoicesLess.suffixBox}>
                    支持拓展名：.PDF .XML
                  </div>
                  <Dgupload
                    fileName="发票"
                    type={[".pdf", ".xml"]}
                    setValue={setEleInvoiceUrl}
                    value={eleInvoiceUrl}
                    button={true}
                  ></Dgupload>
                </div>
              </Form.Item>
              <Form.Item label="备注" name="remark">
                <Input.TextArea rows={4} placeholder="请输入备注" />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 6 }}>
                <Button
                  type="default"
                  className="w-150"
                  onClick={() => setInvoicingVisible(false)}
                >
                  取消
                </Button>
                <Button
                  type="primary"
                  className="w-150 ml-[20]"
                  htmlType="submit"
                >
                  保存
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      ) : (
        <EmptyPage />
      )}
    </div>
  );
});
