import Dgupload from "../../components/dg-upload";
import { Button, Collapse, message } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
// import proceedingDetailLess from "./css/detail.less";
import payLess from "./css/pay.less";
import { memo, useEffect, useState } from "react";
import Dglist from "../../components/dg-list";
import Dglistitem from "../../components/dg-list-item";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import ajax from "../../assets/js/ajax";

const { Panel } = Collapse;

let navigate: NavigateFunction | null = null;

export default memo(() => {
  navigate = useNavigate();
  const params = useLocation().state as {
    tradeNo: string;
  };

  const [cert, setCert] = useState<string>("");
  const [data, setData] = useState<any>({});

  const getDetail = () => {
    ajax.post({
      url: "/openPlatform/accidentPreventionOrder/payInfo",
      data: params,
      self: this,
      success: (res) => {
        setData(res || {});
      },
      fail: () => {},
    });
  };

  // 复制信息
  const copyItem = (content: string) => {
    let txt = content;
    if (navigator.clipboard && window.isSecureContext) {
      message.success("复制成功");
      return navigator.clipboard.writeText(txt);
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = txt;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      message.success("复制成功");
      return new Promise<void>((res, rej) => {
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  };

  const copyAll = () => {
    let bankAccount = `开户名称：${data.payBankAccount}\n`;
    let bankDot = `开户银行：${data.payBankDot}\n`;
    let bankCardNo = `银行账号：${data.payBankCardNo}\n`;
    let amount = `汇款金额：${data.amount}\n`;
    let remark = `备注：${data.payRemark}\n`;
    let content = bankAccount.concat(bankDot, bankCardNo, amount, remark);
    // let ipt = document.getElementById("copyTxt");

    let txt = content;
    if (navigator.clipboard && window.isSecureContext) {
      message.success("复制成功");
      return navigator.clipboard.writeText(txt);
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = txt;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      message.success("复制成功");
      return new Promise<void>((res, rej) => {
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  };

  const handleSubmit = () => {
    if (!cert) {
      return message.warning("请上传支付凭证");
    }
    ajax.post({
      url: "/openPlatform/accidentPreventionOrder/uploadOfflinePayVoucher",
      data: { ...params, payVoucherUrl: cert },
      self: this,
      success: (res) => {
        if (res.code == 200) {
          message.success("支付成功");
          navigate!("/admin/accident/detail", {
            state: { tradeNo: params.tradeNo, open: true },
          });
        }
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <div>
      <div className="pb-[12]">
        <span className="t-[16] aui-192530 pr-[4]">支付</span>
      </div>
      <div className={payLess.content}>
        <div className={payLess.title}>
          <p>
            <i className={`${payLess.icons} icon`}>&#xe638;</i>
            <span className={payLess.t1}>
              安评公司已受理并报价，请尽快完成支付！
            </span>
          </p>
          <p>
            应付金额：<span className={payLess.amount}>{data.amount}</span>元
          </p>
        </div>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="事故预防服务订单编号">{data.tradeNo}</Dglistitem>
          <Dglistitem label="提交时间">{data.gmtCreate}</Dglistitem>
          <Dglistitem label="商品名称">{data.productName}</Dglistitem>
        </Dglist>
        <div className={payLess.ct}>选择支付方式：</div>
        <Collapse
          accordion
          expandIconPosition="end"
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Panel
            header={
              <div className={payLess.callhead}>
                <i className="icon">&#xe62b;</i>
                <span>线上支付（支持微信支付）</span>
                <a className="aui-link">请点击</a>
              </div>
            }
            key="1"
          >
            <p className={payLess.nopay}>
              暂不支持，请选择<span>转账汇款</span>方式
            </p>
          </Panel>
          <Panel
            header={
              <div className={payLess.callhead}>
                <i className="icon">&#x100dc;</i>
                <span>
                  转账汇款（获取汇款账户详细信息，上传付款凭证完成支付）
                </span>
                <a className="aui-link">请点击</a>
              </div>
            }
            key="2"
          >
            <div className={payLess.paycontent}>
              <div className={payLess.paytitle}>收款人信息</div>
              <div className={payLess.info}>
                <div className={payLess.label}>开户名称：</div>
                <div className={payLess.value}>{data.payBankAccount}</div>
                <div
                  className={payLess.copy}
                  onClick={() => {
                    copyItem(data.payBankAccount);
                  }}
                >
                  复制
                </div>
              </div>
              <div className={payLess.info}>
                <div className={payLess.label}>开户银行：</div>
                <div className={payLess.value}>{data.payBankDot}</div>
                <div
                  className={payLess.copy}
                  onClick={() => {
                    copyItem(data.payBankDot);
                  }}
                >
                  复制
                </div>
              </div>
              <div className={payLess.info}>
                <div className={payLess.label}>银行账号：</div>
                <div className={payLess.value}>{data.payBankCardNo}</div>
                <div
                  className={payLess.copy}
                  onClick={() => {
                    copyItem(data.payBankCardNo);
                  }}
                >
                  复制
                </div>
              </div>
              <div className={payLess.info}>
                <div className={payLess.label}>汇款金额：</div>
                <div className={payLess.value}>{data.amount}</div>
                <div
                  className={payLess.copy}
                  onClick={() => {
                    copyItem(data.amount);
                  }}
                >
                  复制
                </div>
              </div>
              <div className={payLess.info}>
                <div className={payLess.label}>备注：</div>
                <div className={payLess.value}>{data.payRemark}</div>
                <div
                  className={payLess.copy}
                  onClick={() => {
                    copyItem(data.payRemark);
                  }}
                >
                  复制
                </div>
              </div>
              <div className={payLess.copyall} onClick={copyAll}>
                一键复制
              </div>
              <div className={`${payLess.uploaditem} aui-row`}>
                <div
                  className={`${payLess.w_120} float-left text-right`}
                >
                  <span className={`${payLess.required} aui-788593`}>
                    上传付款凭证：
                  </span>
                </div>
                <div className="float-left">
                  <Dgupload setValue={setCert} value={cert}></Dgupload>
                  <p className="pt-[4] aui-999CA1 t-[12]">
                    请上传.png.jpg.jpeg,文件大小不超过10M
                  </p>
                </div>
              </div>
              <div className="text-center mt-[32]">
                <Button type="primary" onClick={handleSubmit}>
                  确定
                </Button>
              </div>
            </div>
          </Panel>
        </Collapse>
        <div className="text-center mt-[32]">
          <Button type="primary" className="w-160" onClick={getDetail}>
            刷新
          </Button>
        </div>
      </div>
    </div>
  );
});
